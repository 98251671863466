<template>
    <div id="terms" class="container app-content">
        <h1>Privacy Policy for Bubs Media LLC</h1>
        <p>Last modified: February 18, 2020</p>
      <div class="form-page">
        

        <section class="section">
            <p>Bubs Media LLC (hereafter “Bubs”, “we,'' “our”, “us” “Site”) recognizes the importance of privacy and the sensitivity of personal information. These Website Standard Terms and Conditions (“Terms”) written on this webpage shall manage your use of our website, Bubs, accessible at BubsJournal.com.</p>
            <p>By using this Website, you agreed to accept all terms and conditions.</p>
            <p>We value your information. We won’t sell it. We’ll protect it. We’ll do our best.</p>
        </section>
        <section class="section">
            <h2>INFORMATION WE COLLECT</h2>
            <p>We may collect different types of information during your interactions with our Site.</p>
            <p>This information may include personal information (e.g., name, phone number, address, email address, and certain payment information) and technical information (e.g., technical information, including device identifier, IP address, browser type, operating system).</p>
            <p>We may combine these types of information together, and collectively refer to all of this information in this Privacy Policy as “Information.” Information may be collected as described below and through the use of cookies, web beacons, pixels, and other similar technologies by us or by other companies on our behalf.</p>
        </section>

        <section class="section">
            <h2>HOW WE USE YOUR INFORMATION</h2>
            <p>We use Information for the purposes described in this policy or disclosed at the time of collection or with your consent.</p> 
        </section>

        <section class="section">
            <h2>USE OF TECHNICAL AND USAGE INFORMATION</h2>
            <p>We use technical and usage information to improve the design, functionality and content of our Site and to enable us to personalize your experience with our Site and offerings.</p> 
            <p>We use this information:</p>
            <ol>
                <li>to provide, develop, maintain, personalize, protect, and improve our Site, products, programs, and services and to operate our business,</li> 
                <li>to perform analytics, including to analyze and report on usage and performance of our Site, </li>
                <li>to protect against, identify, and prevent fraud and other unlawful activity, </li>
                <li>to create aggregate data about groups or categories of our users, and </li>
                <li>for us and our Affiliates, business partners, and select third parties to target, offer, market, or advertise products, programs, or services.</li>
            </ol>
        </section>

        <section class="section">
            <h2>COMPLIANCE</h2>
            <p>We may use Information we collect to detect, investigate, and prevent activities on our Site that may violate our terms of use, could be fraudulent, violate copyright, or other rules or that may be otherwise illegal, to comply with legal requirements, and to protect our rights and the rights and safety of our users and others.</p>
        </section>

        <section class="section">
            <h2>INFORMATION SHARING AND DISCLOSURE</h2>
            <p>We may share and disclose Information in the following ways or for any other purpose disclosed at the time of collection:</p>
            <ol>
                <li>With your consent - We may disclose Information when you provide us with your consent to do so.</li>
                <li>Legal and law enforcement purposes - We may disclose Information in response to legal process, for example in response to a court order or a subpoena, or in response to a law enforcement agency's request. We also may disclose such Information to third parties: (i) in connection with fraud prevention activities, (ii) where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, (iii) in situations that may involve violations of our terms of use or other rules, (iv) to protect our rights and the rights and safety of others, and (v) as otherwise required by law.</li>
                <li>Change of control - We may transfer Information in the event of a business transaction, such as if we or one of our business units or our relevant assets are acquired by, sold to, or merged with another company or as part of a bankruptcy proceeding or a business reorganization.</li>
                <li>Service providers - Our agents and contractors may have access to Information to help carry out the services they are performing for us, such as, but not limited to, creation, maintenance, hosting, and delivery of our Site, products, and services, conduct marketing, handle payments, email and order fulfillment, administer contests, conduct research and analytics, or customer service.</li>
            </ol>
        </section>

        <section class="section">
            <h2>COOKIES AND OTHER TECHNOLOGIES</h2>
            <p>We may send "cookies" to your computer or use similar technologies to understand and enhance your online experience at our Site.</p>
            <p>We may use cookies and other technologies to help recognize your browser or device, maintain your preferences, provide certain Site features, and collect Information about interactions with our Site, our content, and our communications. </p>
            <p>We may also use cookies and other technologies (i) to provide, develop, maintain, personalize, protect, and improve our Site, products, programs, and services and to operate our business, (ii) to perform analytics, including to analyze and report on usage and performance of our Site and marketing materials, (iii) to protect against, identify, and prevent fraud and other unlawful activity, (iv) to create aggregate data about groups or categories of our users, (v) to synchronize users across devices, affiliates, business partners, and select third parties, and (vi) for us and our affiliates, business partners, and select third parties to target, offer, market, or advertise products, programs, or services. Cookies and other technologies also facilitate, manage, and measure the performance of advertisements displayed on or delivered by or through us and/or other networks or sites. By visiting the Site, whether as a registered user or otherwise, you acknowledge, and agree that you are giving us your consent to track your activities and your use of the Site through the technologies described above, as well as similar technologies developed in the future, and that we may use such tracking technologies in the emails we send to you.</p>
            <p>Our Site uses Google Analytics to analyze traffic. </p>
        </section>

        <section class="section">
            <h2>NOTIFICATION REGARDING PRIVACY POLICY UPDATES</h2>
            <p>We will post any changes to this Privacy Policy on this page.</p>
        </section>

        <section class="section">
            <h2>HOW TO CONTACT US</h2>
            <p>If you have any questions about this Privacy Policy you may contact us at info@bubsjournal.com.</p>
         </section>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"

export default {
  name: 'Privacy',
  data () {
    return {

    }
   },
  methods: {
    
  },
   computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  created () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style: disc;
  padding-left: 50px;
}

ol {
  list-style: number;
  padding-left: 50px;
}

p {
  padding: 5px;
}

section {
  padding: 25px;
}
</style>