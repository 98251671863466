<template>
  <div class="container app-content">
    <h1>Edit Memory</h1>
    <br>
    <input type="text" placeholder="Title . . ." class="input" v-model="memory_name" required/>  
    
    <editor v-model="model" :value="memory_text" v-if="model!=''"></editor>
    
    <div id="tag-input">
    <vue-tags-input v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" class="input" placeholder="Add some tags . . ."/>
    </div>
    <br><br>
    <div class="upload-field" v-if="picture==null || picture==''">
      <div class="field">
        <div class="file is-small">
          <label class="file-label">
            <input class="file-input" type="file" @change="previewImage" accept="image/*" name="resume">
              <span class="file-cta">
              <span class="file-icon">
                <font-awesome-icon icon="upload" />
              </span>
              <span class="file-label">
                Add an image to this memory
              </span>
              </span>
          </label>
        </div>
      </div>
    </div>

    
    <div v-if="imageData!=null" id="progress-bar" class="container">
      <p>Progress: {{uploadValue.toFixed()+"%"}}
      <progress class="progress is-info is-small" id="progress" :value="uploadValue" max="100" ></progress>  </p>
    </div>
    <div>
      <img :src="picture">
    </div>
    <button class="button whitebutton" v-if="picture!=null && picture!=''" @click="removeImage">Remove Image</button>
    <br><br>
    <div id="save_button">
      <button class="button" v-if="model!='' || uploadValue!=0" v-bind:disabled="hasSaved" @click='saveMemory'>{{ save_text }}</button>
    </div>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage';
import { db } from '@/main'
import editor from '@/components/Editor'
import IdeaDisplay from '@/components/IdeaDisplay'
import VueTagsInput from '@johmun/vue-tags-input';
import ideas from '@/ideas.json'

library.add(
  faUpload
)

export default {
  name: 'EditMemory',
  components: {
    editor,
    VueTagsInput
  },
  data () {
    return {
      model: '',
      imageData: null,
      picture: '',
      uploadValue: 0,
      image_url: '',
      save_text: 'Save your memory',
      hasSaved: false,
      tag: '',
      tags: [],
      memory_name: '',
      memory_text: '',
      memory_image: '',
      old_image: ''
    }
   },
  methods: {
    getMemoryInfo() {
      var self = this;
      db.collection('UserPreferences').doc(self.user.data.uid).collection("Memories")
        .doc(self.memory_id)
        .get()
        .then(doc => {
          var data = doc.data()
          self.memory_name=data.memory_name
          self.memory_text=data.memory_text
          self.memory_image=data.memory_image
          self.picture=data.memory_image
          self.tags=data.memory_tags
          if (data.memory_text=='') {
            self.model='Add your memory text here'
          }
          else {
            self.model=self.memory_text
          }
        })
    },
    convertDate(memory_date) {
      let options = {year: 'numeric', month: 'long', day: 'numeric' };
      let returnDate=new Date(memory_date*1000)

      let dateString=returnDate.toLocaleDateString("en-US", options)

      return dateString
    },
    saveMemory () {
      var self = this;    

      self.save_text="Saving . . ."
      self.hasSaved=true
      
      db.collection('UserPreferences').doc(self.user.data.uid).collection("Memories").doc(self.memory_id).set({
        memory_name: self.memory_name,
        memory_text: self.model,
        memory_image: self.picture,
        memory_tags: self.tags
        },{merge:true})
      .then(function() {
        self.$router.replace({ name: 'MemoryList'})
      })
    },
    previewImage(event) {
      var self = this;
      self.hasSaved = true

      self.uploadValue=0;
      self.picture=null;
      self.imageData = event.target.files[0];
      
      var reader = new FileReader();
      var image = new Image();

      reader.onload = function(event) {
        image.src = event.target.result;
      };

      
      reader.readAsDataURL(self.imageData);

      image.onload = function() {
        let imageheight=image.naturalHeight
          let imagewidth=image.naturalWidth
          let ratio=0

          if (imageheight>imagewidth) {
            ratio=imagewidth/imageheight
            imageheight=320
            imagewidth=imageheight*ratio
          }
          else {
            ratio=imageheight/imagewidth
            imagewidth=320
            imageheight=imagewidth*ratio
          }

        const elem = document.createElement('canvas'); 
        document.body.appendChild(elem);  
        elem.style.visibility = "hidden"
        elem.width = imagewidth
        elem.height = imageheight
        
        const ctx = elem.getContext('2d');
        ctx.drawImage(image, 0, 0, imagewidth, imageheight);

        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], file_name, {type: 'image/png',lastModified: Date.now()}) 
          let uid=self.uuidv4()
          let file_name=uid + ".png"
          const storageRef=firebase.storage().ref(`/${self.user.data.uid}/${file_name}`).put(file);

          storageRef.on(`state_changed`,snapshot=>{
          self.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
          ()=>{self.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              self.picture=url;
              self.hasSaved = false
            })
          })
        }, 'image/png', 1)   
      }
      
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    removeImage() {
      this.picture=''
    }
  },
  props: ['memory_id'],
  computed: {
    ...mapGetters ({
      user:"user"
    }),
    filteredItems() {
      return this.autocomplete.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },

  },
  mounted () {
    this.getMemoryInfo()
  }
}
</script>

<style scoped>

#text_editor {
  margin: 25px;
}

.upload-field {
  display: flex;
  justify-content: center;
  padding: 25px;
  width: 100%;
  margin: auto;
}

img.preview {
    width: 200px;
}

#progress-bar {
  width: 200px;
}

.whitebutton {
  background-color: #ffffff;
  color:#000000;
}
</style>