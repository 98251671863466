import Vue from 'vue'
import App from './App.vue'
import './../node_modules/bulma/css/bulma.css'
import store from "@/store";
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faComment, faBook, faImages, faLock, faEnvelope, faUserTimes, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { firestorePlugin } from 'vuefire'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import '@/assets/main.css'
import '@/mixins/generalFunctions'
import './registerServiceWorker'
import Vuelidate from 'vuelidate'

let app = ''

Vue.use(firestorePlugin)
Vue.use(Vuelidate)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

library.add(
  faComment,
  faBook,
  faImages,
  faLock,
  faEnvelope,
  faFacebook,
  faTwitter,
  faInstagram,
  faUserTimes,
  faEdit,
  faTrash
  )

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: "G-JNZQN4S8N3"
};

firebase.initializeApp(firebaseConfig)

firebase.firestore().enablePersistence()
firebase.analytics();

export const db = firebase.firestore()

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
  

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
 })

