<template>
  <div class="container app-content form-page">
    <h1>Reset Your Password</h1><br>
      <p class='status-message'>{{ status_message }}</p>
        <div class="field">
          <label for class="label">Old Password</label>
            <div class="control has-icons-left">
              <input
                type="password"
                placeholder="Old Password"
                  class="input"
                  v-model="oldpassword"
                  required
                  autofocus
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="lock" />
                </span>
              </div>
            </div>

            <div class="field">
              <label for class="label">New Password</label>
              <div class="control has-icons-left">
                <input
                  type="password"
                  placeholder="New Password"
                  class="input"
                  v-model="newpassword"
                  required
                  autofocus
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="lock" />
                </span>
              </div>
            </div>

            <div class="field">
              <label for class="label">Confirm the New Password</label>
              <div class="control has-icons-left">
                <input
                  type="password"
                  placeholder="New Password Again"
                  class="input"
                  v-model="newpasswordconfirm"
                  required
                  autofocus
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="lock" />
                </span>
              </div>
            </div>
          
            <div class="field">
              <button class="button" @click='submit'>Reset Your Password</button>
            </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import router from '@/router'

export default {
  name: "ResetPassword",
  data() {
    return {
      oldpassword: '',
      newpassword: '',
      newpasswordconfirm: '',
      status_message: ''
    };
  },
  methods: {
    submit() {
      var self = this;

      if (this.newpassword!=this.newpasswordconfirm) {
        this.status_message='Your new password does not match the confirm password. Please try again.'
      }
      else {
        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
        user.email, 
        this.oldpassword
      );

      user.reauthenticateWithCredential(credential)
      .then (function() {
        user.updatePassword(self.newpassword)
        .then(function() {
          self.$router.push({ name: 'Account' })
        })
        .catch(function(error) {
          console.log(error)
        })
      })
      .catch(function(error){
        console.log(error)
      })
      }
    },
  },
}
</script>

<style scoped>
.box {
  background-color: #cccccc;
}
</style>
