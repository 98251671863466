<template>
  <div class="container app-content">
    <h1>Idea Library</h1>
    <h2>A whole library of ideas to inspire you…</h2>
    <div class="container">
      <input
        class="input"
        type="text"
        v-model="search_text"
        placeholder="Enter your search term here..."
      />
      <br><br>

      <ul :style="gridStyle" class="card-list">
			
		  </ul>
      

      <ul>
        <li v-for="(idea, idx) in filteredIdeas" :key="idx"><router-link :to="`/NewMemory/${idea[0].ideaindex}|x`">{{ idea.Idea }}</router-link></li>
      </ul>
    </div>
    <br />

  	<ul :style="gridStyle" class="card-list">
      <li v-for="(person, idx) in people" :key="idx" class="card-item">
        <router-link :to="`/personalideas/${person.id}`">
          <img :src='personAvatar(person.avatar)' width=320 height=320>
         <p>{{ person.first_name }} {{ person.last_name }}</p>
        </router-link>
      </li>
    </ul>


    <ul :style="gridStyle" class="card-list">
			<li v-for="(category, idx) in filteredCategories" :key="idx" class="card-item">
        <router-link :to="`ideas/${category.Link}`"><img :src='computedSrc(category.Link)'></router-link></li>
		</ul>
  
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { mapGetters } from "vuex"
import firebase from "firebase/app";
import "firebase/firestore";
import { db } from "@/main";
import ideacategories from '@/categories.json'
import ideas from '@/ideas.json'

export default {
  name: "IdeaLibrary",
  components: {},
  data() {
    return {
      idealist: ideas,
      searchResults: [],
      categoryResults: ideacategories,
      people: [],
      search_text: "",
      categories: ideacategories,
      numberOfColumns: 3
    };
  },
  firestore() {
    return {
      people: db.collection('UserPreferences').doc(this.user.data.uid).collection('People')

    };
  },
  methods: {
     computedSrc(category) {
      return "/categories/" + category + ".jpg"
    },
    personAvatar (avatar) {
      if (avatar=='') {
        return 'img/default-avatar.jpg'
      }
      else {
        return avatar
      }
    },
  },

  computed: {
    ...mapGetters ({
      user:"user"
    }),
    filteredIdeas: function() {
      let self = this;
      self.searchResults=[]
      self.searchResults.push()
      if (self.search_text != "") {
        let searchtext=self.search_text.replace('4th','fourth')
        searchtext=searchtext.toLowerCase()

        self.idealist.forEach (function(entry, i) {
          if (entry.Personalized!="Y") {
            let idea_text=entry.Idea.toLowerCase()      
            if (idea_text.indexOf(searchtext)>-1) {
              let ideaindex=[{'ideaindex':i}]
              Array.prototype.push.apply(entry, ideaindex)
              self.searchResults.push(entry);
            }
          }
        })
      }
      else {
        self.searchResults=[]
      }
      return self.searchResults
    },
    filteredCategories: function() {
      if (this.search_text=='') {
        
        return this.categories
      }
      else {
        let searchtext=this.search_text.replace('4th','fourth')
        return this.categories.filter(i => {
        return i.Category.toLowerCase().indexOf(searchtext.toLowerCase()) !== -1;
      });
      }
      
    },
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`
      }
    },

  },
  created() {}
};
</script>

<style scoped>
.person-box {
  background-image: url("/img/personalized.jpg");
}

.card-list {
  display: grid;
  grid-gap: 1em;
}

</style>
