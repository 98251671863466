<template>
  <div class="app-content form-page">
    <h1>How to create a memory</h1>
<p>A memory is a journal entry. You can enter as many as you want each day. You can tag the memory (for more information on how to tag a memory, refer to the &ldquo;How to tag a memory&rdquo; section) and you can save all your memories as PDFs (for more information on how to save your memories as PDFs, refer to the &ldquo;How to save memories as a PDF&rdquo; section).</p>

<p>To create a memory:</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;New Memory&rdquo;. The &ldquo;New Memory&rdquo; page appears.</li>
    <li>The idea or inspiration for your memory appears. You can use that idea or click the &ldquo;Inspire Me&rdquo; button to see another idea. You can continue to click the button until you find an idea that inspires you.</li>
    <li>In the first text box, the date appears. This is the title of your memory. If you don&rsquo;t want the title of your memory to be your date, enter your new title.</li>
    <li>In the &ldquo;Create memory here&hellip;&rdquo; text box, type your memory. You can change the size of the font, use a color, or change the font type. You can write as much or as little as you want.</li>
    <li>In the &ldquo;Add some tags...&rdquo; text box, you can enter one or more tags to help you categorize your memory. For example, if you are writing about Scott, tag &ldquo;Scott&rdquo;. If you are writing about Scott and vacation, enter &ldquo;Scott&rdquo; and &ldquo;Vacation&rdquo;. You can use those tags to:</li>
    <ol type="a">
      <li>On the &ldquo;Memories&rdquo; page, you can search your &ldquo;vacation&rdquo; tag and see all your vacation memories. You can search by more than one tag and it will display all those memories by those tags.</li>
      <li>On the &ldquo;Memories&rdquo; page, you can search your tags and save to PDF those specific tags. If you want to save memories about Scott, search for the &ldquo;Scott&rdquo; tag and save those memories to PDF. You&rsquo;ll have a PDF file of memories of just Scott.</li>
    </ol>
    <li>To attach an image to a memory, click the &ldquo;Add an image to this memory&rdquo; button. A dialog box will appear. Find the image you want to upload. Select the image. The status of the image being uploaded will appear. 
      <p>NOTE: Wait until you see the image appear before saving the memory.</p>
    </li>
    <li>Click the &ldquo;Save your memory&rdquo; button. You can access your memories on the &ldquo;Memory&rdquo; page.</li>

</ol>
<br>
<h1>How to tag a memory</h1>
<p>We use tags to help you categorize your memories. If you are writing about your vacation to Italy with Scott last summer, you could tag that memory with &ldquo;vacation&rdquo;, &ldquo;Italy&rdquo;, &ldquo;Scott&rdquo;, and &ldquo;Summer&rdquo;.</p>
<p>Why should you use tags? By tagging a memory, you are categorizing them. This makes the memories easier to sort. You can also save PDFs of specific tags and memories.</p>
<p>To tag a memory:</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;New Memory&rdquo;. The &ldquo;New Memory&rdquo; page appears.</li>
    <li>In the &ldquo;Add some tags...&rdquo; text box, you can enter one or more tags to help you categorize your memory. For example, if you are writing about Scott, tag &ldquo;Scott&rdquo;. If you are writing about Scott and vacation, enter &ldquo;Scott&rdquo; and &ldquo;Vacation&rdquo;. You can use those tags to:</li>
    <ol type="a">
      <li>On the &ldquo;Memories&rdquo; page, you can search your &ldquo;vacation&rdquo; tag and see all your vacation memories. You can search by more than one tag and it will display all those memories by those tags.</li>
      <li>On the &ldquo;Memories&rdquo; page, you can search your tags and save to PDF those specific tags. If you want to save memories about Scott, search for the &ldquo;Scott&rdquo; tag and save those memories to PDF. You&rsquo;ll have a PDF file of memories of just Scott.</li>
    </ol>
    <li>Click the &ldquo;Save your memory&rdquo; button.</li>
</ol>
<br>
<h1>How to upload an image or picture to a memory</h1>
<p>You can include an image with your memory.</p>
<p>To upload an image to your memory:</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;New Memory&rdquo;. The &ldquo;New Memory&rdquo; page appears.</li>
    <li>To attach an image to a memory, click the &ldquo;Add an image to this memory&rdquo; button. A dialog box will appear. Find the image you want to upload. Select the image. The status of the image being uploaded will appear. 
      <p>NOTE: Wait until you see the image appear before saving the memory.</p>
    </li>
    <li>Click the &ldquo;Save your memory&rdquo; button.
      <p>NOTE: The &ldquo;Save your memory&rdquo; button only appears after your image is completely uploaded.</p>
    </li>

</ol>
<br>
<h1>How to use the Idea Library</h1>
<p>Our Idea Library is full of inspiration. We know that sometimes you want to write, but don&rsquo;t know where to start. The purpose of our Idea Library is to spark your creativity with an idea so you can easily start writing.</p>
<p>Our topics can help you write whatever kind of journal you want to keep. Maybe it is a baby book. Maybe it is your daily journal. Maybe it is a keepsake journal. Maybe it is a micro journal. Maybe it is a photo of the day journal. We want it to be whatever you want it to be. </p>
<p>You can use the Idea Library two ways.</p>
<p>Option 1:</p>
<ol>
  <li>Make sure you are signed into Bubs.</li>
  <li>Click &ldquo;Idea Library.&rdquo; The &ldquo;Idea Library&rdquo; page appears.</li>
  <li>To find ideas, you have two options:</li>
  <ol type="a">
    <li>In the &ldquo;Enter your search term here...&rdquo; text box, enter the topic you want to write about. </li>
    <ol type="i">
      <li>The ideas appear under the Search box. </li>
      <li>Click the idea you want to use. The &ldquo;New Memory&rdquo; page appears. </li>
      <li>Write your memory.</li>
    </ol>
    <li>You can scroll through the different topics until you find a topic that inspires you.</li>
    <ol type="i">
      <li>Click on a topic. A list of ideas appears.</li>
      <li>To use an idea, under the idea, click the &ldquo;Use this idea&rdquo; button. The &ldquo;New Memory&rdquo; page appears.</li>
      <li>Write your memory.</li>
    </ol>
  </ol>
</ol>
<p>Option 2:</p>
<ol>
  <li>Make sure you are signed into Bubs.</li>
  <li>Click &ldquo;New Memory&rdquo;. The &ldquo;New Memory&rdquo; page appears.</li>
  <li>Under the &ldquo;New Memory&rdquo; heading, an idea appears. If you don&rsquo;t want to use the idea, click &ldquo;Inspire Me&rdquo;. Another idea appears. You can continue to click the button until you find an idea that inspires you.</li>
</ol>
<br>
<h1>How to save memories as a PDF</h1>
<p>You cannot save to PDF on a mobile device.</p>
<p>To save memories as a PDF:</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;Memories&rdquo;. The &ldquo;Memories&rdquo; page appears.</li>
    <li>You can save memories to PDF by:</li>

    <ol type="a">
      <li>To create a PDF of all your memories, click &ldquo;Create a PDF of the memories&rdquo;.</li>
      <li>To create a PDF by your tags:</li>
      <ol type="i">
        <li>For &ldquo;Search by tag&rdquo;, enter the tags you want to save.</li>
        <li>Click &ldquo;Create a PDF of the memories&rdquo;.</li>
      </ol>
      <li>To create a PDF of memories by date:</li>
      <ol type="i">
        <li>For &ldquo;Search memories from&rdquo;, enter the beginning date.</li>
        <li>For &ldquo;To&rdquo;, enter the end date of your range.</li>    
        <li>Click &ldquo;Create a PDF of the memories&rdquo;.</li>
      </ol>
      <li>It may take a few seconds to appear. 
        <p>NOTE: If you are on a desktop computer, check your &ldquo;Downloads&rdquo; folder for the file.</p>
      </li>
    </ol>
</ol>
<br>
<h1>How to print memories</h1>
<p>To print your memories:</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;Memories&rdquo;. The &ldquo;Memories&rdquo; page appears.</li>
    <li>To create a PDF of all your memories, click &ldquo;Create a PDF of the memories&rdquo;.</li>
    <li>To create a PDF by your tags:</li>
    <ol type="a">
      <li>For &ldquo;Search by tag&rdquo;, enter the tags you want to save.</li>
      <li>Click &ldquo;Create a PDF of the memories&rdquo;.</li>
    </ol>
    <li>To create a PDF of memories by date:</li>
    <ol type="a">
      <li>For &ldquo;Search memories from&rdquo;, enter the beginning date.</li>
      <li>For &ldquo;To&rdquo;, enter the end date of your range.</li>
      <li>Click &ldquo;Create a PDF of the memories&rdquo;.</li>
    </ol>
    <li>Print the PDF.
      <p>NOTE: If you are on a desktop computer, check your &ldquo;Downloads&rdquo; folder for the file.</p>
    </li>

</ol>
<br>
<h1>How to personalize my ideas</h1>
<p>You can personalize your ideas so they are customized for you and your family. </p>
<p>To personalize your ideas:</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;Account&rdquo;. The &ldquo;Account&rdquo; page appears.</li>
    <li>In the &ldquo;Who are you creating memories about?&rdquo; section, click &ldquo;Add a person&rdquo; for each person you want to create memories about, enter:</li>
    <ol type="a">
      <li>First Name</li>
      <li>Last Name</li>
      <li>Birthday</li>
      <li>Pronoun</li>
      <li>Relationship to You</li>
    </ol>
    <li>To add a picture, click "Add an Image."</li>
    <li>Click &ldquo;Save&rdquo;.</li>
</ol>
<br>
<h1>How to edit a person from your account</h1>
<p>To edit a person from your account</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;Account&rdquo;. The &ldquo;Account&rdquo; page appears.</li>
    <li>For the person you want to change or update his/her information, enter new information for his/her:</li>
    <ol type="a">
      <li>First Name</li>
      <li>Last Name</li>
      <li>Birthday</li>
      <li>Pronoun</li>
     <li>Relationship to You</li>
    </ol>
    <li>To update the picture, click "Change the Image."</li>
    <li>Click &ldquo;Save&rdquo;.</li>
</ol>
<br>
<h1>How to delete a person from your account</h1>
<p>When you delete a person from your account, your ideas will no longer be personalized to include him or her. The memories that are tagged with this person, will continue to appear on the &ldquo;Memories&rdquo; page.</p>
<p>To delete a person from your account:</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;Account&rdquo;. The &ldquo;Account&rdquo; page appears.</li>
    <li>Next to the person you want to delete, click the person icon <img src=/img/deleteperson.png style="width: 25.99px; height: 31.50px;">.</li>
    <li>Click &ldquo;Save&rdquo;. The person is deleted.</li>
</ol>
<br>
<h1>How to search for an idea</h1>
<p>To search for an idea:</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;Idea Library.&rdquo;</li>
    <li>In the &ldquo;Enter your search term here...&rdquo; text box, enter your search criteria.</li>
    <li>Your search results appear as you type.</li>
    <li>Click the idea you want to use. The &ldquo;New Memory&rdquo; page appears.</li>
</ol>
<br>
<h1>How to cancel my membership</h1>
<p>Sorry to see you go! If you cancel your account, you will delete your memories and images and they cannot be restored.</p>
<p>To cancel your membership:</p>
<ol>
    <li>Make sure you are signed into Bubs.</li>
    <li>Click &ldquo;Account&rdquo;. The &ldquo;Account&rdquo; page appears.</li>
    <li>In the &ldquo;Account Membership&rdquo; section, click &ldquo;Cancel your membership&rdquo;. A popup appears.</li>
    <li>Click &ldquo;Delete my account&rdquo;. Your account will be deleted.</li>
</ol>
</div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"

export default {
  name: 'Help',
  data () {
    return {

    }
   },
  methods: {
    
  },
   computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  created () { 
  }
}
</script>

<style scoped>
ol {
  padding-left:40px;
}
</style>