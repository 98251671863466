<template>
  <div class="is-centered">
    <div id="top_logo">
      <img src='@/assets/logo.png' width='250px' height='250px'/>
      <br><br>
      <h1>Because sometimes you feel like writing,<br>but don’t know what to write about.</h1>
      <br>
      <router-link to="/Register"><button class="button bubs-green" v-if="!user.loggedIn">Get started with your free 7-day trial.</button></router-link>
    </div>

    <div class="hero center_area">
      <div class="hero-body">
        <div class="container">
          <h1>
            <br>
            <p>Bubs is an idea-based journal.</p>
            <p>With over a thousand ideas to inspire you, we can help.</p>
          </h1>
        </div>
      </div>
    </div>

    <div id="bottom_section">
      <div class="columns">
        <div class="column">
          <img src="@/assets/Ideas.jpg" >
          <h1>Ideas</h1>
          <ul>
            <li>Our Idea Library has over a thousand ideas to inspire you to write.</li>
          </ul>

        </div>
        <div class="column">
          <img src="@/assets/Memories.jpg">
          <h1>Memories</h1>
          <ul>
            <li>We help you capture your memories before you forget them.</li>
          </ul>

        </div>
        <div class="column">
          <img src="@/assets/Personalized.jpg">
          <h1>Personalized</h1>
          <ul>
            <li>After you enter a few pieces of information, we personalize our ideas for you and your life.</li>
          </ul>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <img src="@/assets/Share.jpg">
          <h1>Share</h1>
          <ul>
            <li>Save your memories to PDF and share the files with your family and friends.</li>
        </ul>
        </div>
        <div class="column">
          <img src="@/assets/Notifications.jpg">
          <h1>Notifications</h1>
          <ul>
            <li>We’ll email you ideas every day, every week, or every month to inspire you to journal.</li>
          </ul>

        </div>
        <div class="column">
          <img src="@/assets/Security.jpg">
          <h1>Security</h1>
          <ul>
            <li>We securely save your password-protected memories. We encrypt your data. We keep you safe.</li>
          </ul>
        </div>
      </div>
      <br>
      </div>
      <router-link to="/Register"><button class="button bubs-green start-button" v-if="!user.loggedIn">Start capturing memories today. Only $1.49 a month after your free 7-day trial.</button></router-link>
      <br><br>
      <h1>You have no idea.</h1>
      <h1>We have thousands.</h1>
<img src='@/assets/logo.png' width='250px' height='250px'/>


  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    
  },
}
</script>

<style scoped>

.center_area {
  background: url('/img/HomePage.jpg')center center; 
  background-size:cover;
  color: #ffffff;
  margin-top: 50px;
  margin-bottom: 100px;
}

.center_area h1 {
  color: #ffffff
}
#bottom_section {
  padding: 50px;
}

.header-text {
  text-align: center;
}

#top_logo {
  padding-top: 35px;
}

.hero {
  min-height: 500px;
}

.title {
  margin-top: 100px;
}

.navbar {
  text-align: right;
}

.start-button {
  height: 70px;
}
</style>
