<template>
    <div ref="editor" id="texteditor"></div>
</template>
<script>
    import Quill from 'quill';

    export default {
        props: {
            value: {
                type: String,
                default: ''
            }
        }, 

        data() {
            return {
                editor: null
            };
        },
        mounted() {
            var toolbarOptions = [
                ['bold', 'italic',],        // toggled buttons
                [{ 'font': [] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown               
                ['clean']                                         // remove formatting button
                ];
            this.editor = new Quill(this.$refs.editor, {
                modules: {
                    toolbar: toolbarOptions
                },
                theme: 'snow',
                placeholder: 'Create memory here . . .'
            });

            this.editor.root.innerHTML = this.value;

            this.editor.on('text-change', () => this.update());
        },

        methods: {
            update() {
                this.$emit('input', this.editor.getText() ? this.editor.root.innerHTML : '');
            }
        }
    }
</script>

<style>
#texteditor {
    background-color: #ffffff;
    min-height: 300px;
}

.ql-toolbar {
    text-align: left;
}
</style>

