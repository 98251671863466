<template>
  <div class="container app-content">
    <h1>Preferences</h1>
    <h3>Before you can start capturing memories, we need to know a little about you...it will help us personalize your ideas and inspire you.</h3>
    <hr>
    <form action="#" @submit.prevent="savePreferences">
      <h2>About You</h2>
      <section class="form-page">
        <label for class="label">Your Birthday</label>

        <div class="field relationship">
          <label for class='label'>Month</label>
          <div class='select'>
            <select v-model='user_birthday_month' required>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </div>

        <div class="field relationship">
            <label for class='label'>Day</label>
            <div class='select'>
              <select v-model='user_birthday_day' required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
          </div>
        </div>

        <div class="field">

            <label for class='label'>Year</label>
            <div class='select'>
              <select v-model='user_birthday_year' required>
                <option v-for="(year, idx) in years" :value="year" :key='idx'>{{ year }}</option>
              </select>
            </div>
        </div>
        </section>
        
        <hr>
        
        <h2>Notifications</h2>
        <section class="form-page">
        
          
          <label for class="label">How often do you want to be reminded to create a memory?</label>
          <div class="select">
                <select v-model="user_notifications">
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Never">Never</option>
                </select>
              </div>
          </section>
    
      <hr>
        <h2>Who are you creating memories about?</h2>
        <section v-for="(row, idx) in rows" :key="idx" class="form-page">
        
        <hr>
        <div class="columns is-mobile">
                    <div class="column">
            <div class="text-center">
              <img v-if="row.avatar!=''" :src="row.avatar" width="200" height="200">
              <button class="button" :id="`trigger${idx}`" @click="setRow(idx)">Add Image</button>
              <avatar-cropper
                :trigger="`#trigger${idx}`"
                :upload-handler="handleUploading"
                :labels="{submit: 'OK', cancel: 'Cancel'}"
                :output-options="imageSize"
                :output-mime-type="imageType"
                :output-quality="imageQuality"
              />
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label for class="label">First Name <font-awesome-icon :icon="['fa', 'user-times']" v-if="row.first_name!=''" @click="removePerson(idx)"/></label>
              <div class="control">
                <input type="text" class="input" v-model="row.first_name" required/>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label for class="label">Last Name</label>
              <div class="control">
                <input type="text" class="input" v-model="row.last_name" required/>
              </div>
            </div>
          </div>
        </div>

                <label for class="label">Birthday</label>

        <div class="field relationship">
          <label for class='label'>Month</label>
          <div class='select'>
            <select v-model='row.birthday_month' required>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </div>

        <div class="field relationship">
            <label for class='label'>Day</label>
            <div class='select'>
              <select v-model='row.birthday_day' required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
          </div>
        </div>

        <div class="field">

            <label for class='label'>Year</label>
            <div class='select'>
              <select v-model='row.birthday_year' required>
                <option v-for="(year, idx) in years" :value="year" :key='idx'>{{ year }}</option>
              </select>
            </div>
        </div>

           <div class="field relationship">
              <label for class="label">Pronoun</label>
              <div class="select">
                <select v-model="row.pronoun" required>
                  <option value="His">His</option>
                  <option value="Her">Her</option>
                  <option value="Their">Their</option>
                </select>
              </div>
            </div>
            <div class="field " >
            <label for class="label">Relationship to You</label>
              <div class="select">
                <select v-model="row.relationship" required>
                  <option value="Son">Son</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Husband">Husband</option>
                  <option value="Wife">Wife</option>
                  <option value="Girlfriend">Girlfriend</option>
                  <option value="Boyfriend">Boyfriend</option>
                  <option value="Partner">Partner</option>
                </select>
              </div>
            </div>
      </section>
      <hr>
      <button class="button whitebutton" type="button" @click="addPerson">{{ personButton }}</button><br>
      <br>
      <hr>
      <button class="button form-page" type="submit">Save</button><br>
      <p>(You can always come back and finish this later.)</p>
    </form>

        <div class="loader-wrapper" v-if="isLoading">
      <div class="loader is-loading">
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage';
import { db } from '@/main'
import axios from 'axios'
import AvatarCropper from "vue-avatar-cropper"

export default {
  name: 'Preferences',
  data () {
    return {
      rows: [],
      user_birthday_month: '',
      user_birthday_day: '',
      user_birthday_year: '',
      user_notifications: "Weekly",
      years:[],
      currentRow: 0,
      avatarurl: '',
      isLoading: false,
      uploadValue: 0,
      imageSize: {...{'width': 100, 'height': 100}},
      imageType: "image/jpeg",
      imageQuality: 0.5

    }
   },
  components: {
    AvatarCropper
  },
  methods: {
    addPerson () {
      this.rows.push({first_name:"", last_name:"",birthday_month:"",birthday_day:'', birthday_year: '', pronoun:"",relationship:"",user_id:""})
    },
    removePerson(index) {
      this.rows.splice(index,1)
    },
    savePreferences() {
      var self=this;
      
      if (self.user_notifications!="Never") {
        db.collection("Notifications").doc(self.user_notifications).update({
          "Emails": firebase.firestore.FieldValue.arrayUnion(self.user.data.uid + "|" + self.user.data.email)
        })
      }

      db.collection("UserPreferences").doc(self.user.data.uid).set({
        user_birthday_month: self.user_birthday_month.toString(),
        user_birthday_day: self.user_birthday_day.toString(),
        user_birthday_year: self.user_birthday_year.toString(),
        user_notifications: self.user_notifications
      })
      .then(function() {
        db.collection('UserPreferences').doc(self.user.data.uid).collection('People').get()
        .then(function(querySnapshot) {
          var batch = db.batch();
          querySnapshot.forEach(function(doc) {
              batch.delete(doc.ref);
          });
          batch.commit();})
          .then(function() {
            self.rows.forEach(function(item) {
              db.collection('UserPreferences').doc(self.user.data.uid).collection('People').doc().set({
                user_id: self.user.data.uid,
                first_name: item.first_name,
                last_name: item.last_name,
                birthday_month: item.birthday_month.toString(),
                birthday_day: item.birthday_day.toString(),
                birthday_year: item.birthday_year.toString(),
                pronoun: item.pronoun,
                relationship: item.relationship
              })
            })
            self.$router.replace({ name: 'NewMemory'})
          })
      })
    },
    buildYears () {
      for (var i = 2020; i > 1920; i--) {
        this.years.push(i)
      }
    },
    handleUploading(cropper) {     
      var self = this;
      self.isLoading=true
      var image = new Image();
      image.src = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime)
      self.rows[self.currentRow].avatar=image.src

      image.onload = function() {
        const elem = document.createElement('canvas');  
        document.body.appendChild(elem);  
        elem.style.visibility = "hidden"
        elem.width = 320
        elem.height = 320

        const ctx = elem.getContext('2d');
        ctx.drawImage(image, 0, 0, 320, 320);
        

        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], file_name, {type: 'image/jpeg',lastModified: Date.now()})
          let uid=self.uuidv4()
          let file_name=uid + ".jpg"
          const storageRef=firebase.storage().ref(`/${self.user.data.uid}/${file_name}`).put(file)

          storageRef.on(`state_changed`,snapshot=>{
            self.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
          ()=>{self.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              self.rows[self.currentRow].avatar=url
              self.isLoading = false
          })
        })
        }, 'image/jpeg', 0.7)
      }
    },   
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    setRow(index) {
      this.currentRow = index
    },
  },
  
   computed: {
    ...mapGetters ({
      user:"user"
    }),
    personButton () {
      if (this.rows.length>0) {
        return "Add another person"
      }
      else {
        return "Add a person"
      }
    }
  },
  mounted() {
    this.buildYears()
  }
}
</script>

<style scoped>
h3 {
  font-size: 1.2em;
}
.relationship {
  float: left;
  padding-right: 10px;
}

.whitebutton {
  background-color: #ffffff;
  color:#000000;
}

.modal-content {
  background-color: #ffffff;
  height: 50%;
}
</style>
