<template>
<div>
  <nav class="navbar" role="navigation" aria-label="main navigation" v-if="!$route.meta.hideNavigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img src="@/assets/logo.png" />
      </a>
      <a
        role="button"
        class="navbar-burger"
        data-target="navMenu"
        aria-label="menu"
        aria-expanded="false"
        @click="isOpen = !isOpen"
        v-bind:class="{'is-active': isOpen}"
        v-if="user.loggedIn"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" v-bind:class="{'is-active': isOpen}" v-if="user.loggedIn">
      <div class="navbar-end">
        <router-link to="/" class="navbar-item" @click.native="isOpen = !isOpen">Home</router-link>
        <router-link to="/NewMemory" class="navbar-item" @click.native="isOpen = !isOpen">New Memory</router-link>
        <router-link to="/MemoryList" class="navbar-item" @click.native="isOpen = !isOpen">Memories</router-link>
        <router-link to="/IdeaLibrary" class="navbar-item" @click.native="isOpen = !isOpen">Idea Library</router-link> 
        <router-link to="/Account" class="navbar-item" @click.native="isOpen = !isOpen">Account</router-link>            
        <a class="navbar-item" href="#" @click="logout">Sign Out</a>
      </div>
    </div>
  </nav>

    <nav class="navbar" role="navigation" aria-label="main navigation" v-if="$route.meta.hideNavigation">
      <div class="navbar-end">
        <div class="navbar-item" v-if="!$route.meta.hideSignIn">
          <div v-if="!user.loggedIn">
            <router-link to="/Login">Sign In</router-link> |
            <router-link to="/Register">Sign Up</router-link>
          </div>

          <div v-if="user.loggedIn">
            <router-link to="/NewMemory">{{ user.data.displayName }}</router-link> |
            <router-link to="/NewMemory" v-if="user.loggedIn">Add a New Memory</router-link>        
          </div>
        </div>
      </div>
    </nav>
</div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "NavBar",
  data: function() {
    return {
      isOpen: false
    };
  },
  methods: {
    logout: function() {
      this.isOpen = false
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace('/login')
        });
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  }
};
</script>

<style scoped>
.navbar img {
  max-height: 75px;
}
a {
  color: #454141;
}
</style>
