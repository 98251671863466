<template>
  <div class="container app-content">
    <h1>New Memory</h1>
    <IdeaDisplay v-if="notPersonalized"></IdeaDisplay>
    <br />
    <div class='idea-text'>
      {{ ideatext }}
    </div>

    {{filteredPeople}}
    <input type="text" placeholder="Title . . ." class="input title-box" v-model="memory_name" required/>  

    <editor v-model="model" value="ideatext"></editor>
    
    <div id="tag-input">
    <vue-tags-input v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" class="input" placeholder="Add some tags . . ."/>
    </div>
    <div class="upload-field">
      <div class="field">
        <div class="file is-small">
          <label class="file-label">
            <input class="file-input" type="file" @change="previewImage" accept="image/*" name="resume">
              <span class="file-cta">
              <span class="file-icon">
                <font-awesome-icon icon="upload" />
              </span>
              <span class="file-label">
                Add an image to this memory
              </span>
              </span>
          </label>
        </div>
      </div>
    </div>

    <div v-if="imageData!=null" id="progress-bar" class="container">
      <p>Progress: {{uploadValue.toFixed()+"%"}}
      <progress class="progress is-info is-small" id="progress" :value="uploadValue" max="100" ></progress>  </p>
    </div>
    <div v-if="imageData!=null">
      <img class="preview" :src="picture">
    </div>
    <br>
    <div id="save_button">
      <button class="button" v-if="model!='' || uploadValue!=0" v-bind:disabled="hasSaved" @click='saveMemory'>{{ save_text }}</button>
    </div>

    <div class="modal is-active" v-if="showDialog">
      <div class="modal-background"></div>
        <div class="modal-content">
          <div class='container'>
            <br><br><br>
            <h2>Are you sure you want to leave?</h2>
            <p>You have unsaved changes.  Are you sure you want to leave?</p>
            <button class="button" @click='goAway'>Yes</button>&nbsp;
            <button class="button" @click='stayHere'>No</button>&nbsp;
          </div>
        </div>      
    </div>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage';
import { db } from '@/main'
import editor from '@/components/Editor'
import IdeaDisplay from '@/components/IdeaDisplay'
import VueTagsInput from '@johmun/vue-tags-input';
import ideas from '@/ideas.json'
import nationaldays from '@/nationaldays.json'

library.add(
  faUpload
)

export default {
  name: 'NewMemory',
  components: {
    editor,
    IdeaDisplay,
    VueTagsInput
  },
  data () {
    return {
      model: '',
      memory_name: new Date(),
      imageData: null,
      picture: null,
      uploadValue: 0,
      image_url: '',
      save_text: 'Save your memory',
      hasSaved: false,
      tag: '',
      tags: [],
      notPersonalized: true,
      idealist: ideas,
      dayslist: nationaldays,
      ideaid: '',
      ideatext: '',
      temptext: '',
      firstname: '',
      pronoun: '',
      people: [],
      autocomplete: [],
      showDialog: false,
      to: null
    }
   },
  firestore() {
     return {
       people: db.collection('UserPreferences').doc(this.user.data.uid).collection('People')
     }
   },
  methods: {
    saveMemory () {
      var self = this;
      self.save_text="Saving . . ."
      self.hasSaved=true
      db.collection('UserPreferences').doc(self.user.data.uid).collection("Memories").doc().set({
        user_id: self.user.data.uid,
        memory_name: self.memory_name,
        memory_text: self.model,
        memory_date: firebase.firestore.Timestamp.now(),
        memory_image: self.picture,
        memory_tags: self.tags
        })
      .then(function() {
        self.$router.replace({ name: 'MemoryList'})
      })
    },
    previewImage(event) {
      var self = this;
      self.hasSaved = true

      self.uploadValue=0;
      self.picture=null;
      self.imageData = event.target.files[0];
      
      var reader = new FileReader();
      var image = new Image();

      reader.onload = function(event) {
        image.src = event.target.result;
      };

      
      reader.readAsDataURL(self.imageData);

      image.onload = function() {
        let imageheight=image.naturalHeight
          let imagewidth=image.naturalWidth
          let ratio=0

          if (imageheight>imagewidth) {
            ratio=imagewidth/imageheight
            imageheight=320
            imagewidth=imageheight*ratio
          }
          else {
            ratio=imageheight/imagewidth
            imagewidth=320
            imageheight=imagewidth*ratio
          }

        const elem = document.createElement('canvas'); 
        document.body.appendChild(elem);  
        elem.style.visibility = "hidden"
        elem.width = imagewidth
        elem.height = imageheight
        
        const ctx = elem.getContext('2d');
        ctx.drawImage(image, 0, 0, imagewidth, imageheight);

        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], file_name, {type: 'image/png',lastModified: Date.now()}) 
          let uid=self.uuidv4()
          let file_name=uid + ".png"
          const storageRef=firebase.storage().ref(`/${self.user.data.uid}/${file_name}`).put(file);

          storageRef.on(`state_changed`,snapshot=>{
          self.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
          ()=>{self.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              self.picture=url;
              self.hasSaved = false
            })
          })
        }, 'image/png', 1)   
      }
      
    },
    convertImageToCanvas(image) {
	    var canvas = document.createElement("canvas");
	    canvas.width = 200;
	    canvas.height = 200;
	    canvas.getContext("2d").drawImage(image, 0, 0, image.offsetWidth, image.offsetHeight, 0,0,200,200);

	    return canvas;
    },
    convertCanvasToImage(canvas) {
	    var image = new Image();
	    image.src = canvas.toDataURL("image/png");
	    return image;
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    formatName (value) {
        let datemonth=value.getMonth()
        let dateday=value.getDate()
        let dateyear=value.getFullYear()

        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        this.memory_name = months[datemonth] + " " + dateday + ", " + dateyear
    },
    setIdeaText () {
      var self = this;

      if (self.idea_id) {
        self.notPersonalized=false

        let ideaarray = self.idea_id.split("|")
        
        if (ideaarray[0].substring(0,1)=="n") {
          let ideanumber=ideaarray[0].substring(1)
          self.ideatext="It's " + self.dayslist[ideanumber].National_Day + "!"
        }
        else {
          self.temptext=self.idealist[ideaarray[0]].Idea
        
            if (ideaarray[1]!='x') {
              db.collection('UserPreferences').doc(self.user.data.uid).collection('People').doc(ideaarray[1]).get()
              .then(doc => {
                
                var data = doc.data()
                self.firstname = data.first_name
                self.pronoun = data.pronoun
                
                self.temptext=self.replaceName(self.temptext,self.firstname,self.pronoun)
                self.ideatext=self.temptext
              })
            }
            else {
              self.ideatext=self.temptext
            }
        }     
    }
    else {
      self.notPersonalized=true
    }
    },
    stayHere() {
      this.showDialog = false;
      this.to = null;
    },
    goAway() {
      // add code to save changes here
      this.showDialog = false;
      this.$router.push(this.to);
    },
  },
  props: ['idea_id'],
  computed: {
    ...mapGetters ({
      user:"user"
    }),
    filteredPeople () {
      var self = this;
      if(this.people.length>0) {
        this.people.forEach(function (item) {
          self.autocomplete.push({"text":item.first_name})
        }

        )
      }
      
    },
    filteredItems() {
      return this.autocomplete.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },

  },
  beforeRouteLeave(to, from, next) {
    if (to.name=='Login') {
      next()
    }
    else {
      if(!this.hasSaved) {
        if (this.to) {
          next();
        } 
        else {
          this.to = to;
          this.showDialog = true;
        }
    }
    else {
      next();
    }
    }
    
  },
  mounted () {
    this.formatName(this.memory_name)
    
    this.setIdeaText()
  }
}
</script>

<style scoped>
h1 {
  margin-bottom: 15px;
}
#text_editor {
  margin: 25px;
}

.upload-field {
  display: flex;
  justify-content: center;
  padding: 25px;
  width: 100%;
  margin: auto;
}

img.preview {
    width: 200px;
}

#progress-bar {
  width: 200px;
}

.title-box {
  margin-bottom: 15px;
}

.idea-text {
  padding-bottom: 10px;
}

.modal-content {
  background-color: #ffffff;
  height: 50%;
}
</style>