<template>
<div id="footer">
  <div class="container">

    <div class="columns">
      <div class="column footer-column">
        <p class="column-header">Need Help?</p>
        <router-link to="/Help">Help</router-link>
      </div>
      <div class="column footer-column">
        <p class="column-header">Customer Service</p>
        <p><router-link to="/ContactUs">Contact Us</router-link></p>
        <p><router-link to="/FAQ">FAQs</router-link></p>
      </div>
        <div class="column footer-column">
        <p class="column-header">About Bubs</p>
        <p><router-link to="/WhoWeAre">Who We Are</router-link></p>
        <p><router-link to="/HowItWorks">How it Works</router-link></p>
        <p><a href='https://blog.bubsjournal.com' target="_blank">Blog</a></p>

      </div>
    </div>

  <a href='https://www.facebook.com/BubsJournal' target='_blank'><font-awesome-icon :icon="['fab', 'facebook']" size="2x"/></a>&nbsp;
  <a href='https://www.instagram.com/bubs_journal' target='_blank'><font-awesome-icon :icon="['fab', 'instagram']" size="2x" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <a href='https://www.twitter.com/bubsjournal' target='_blank'><font-awesome-icon :icon="['fab', 'twitter']" size="2x" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  
  <p>Copyright 2020 Bubs Media LLC</p>
  <router-link to="/Privacy">Privacy Policy</router-link> | <router-link to="/Terms">Terms</router-link> 
  </div>
</div>
  
</template>

<script>

export default {
  name: "Footer",

};
</script>

<style scoped>
#footer {
  margin-top: 50px;
  padding-top: 25px;
  padding-left: 50px;
  background-color: #cccccc;
  width: 100%;
}

.column-header {
  font-weight: bold;
}

.footer-column {
  text-align: left;
}
a {
  color: #454141;
}

p {
  padding: 0px;
}
</style>
