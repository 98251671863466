<template>
  <div class="container app-content">
    <h1>Account</h1>
    <hr>
      <h2>About You</h2>
      <section class="form-page">
        <div class="field">
          <label for class="label">Your Name</label>
            {{ this.user.data.displayName}}
        </div>
          
          <div class="field">
        <label for class="label">Your Email Address</label>
        {{ this.user.data.email }}
        </div>

        <div class="field">
        <label for class="label">Your Password</label>
        <router-link to="/ResetPassword">Reset your Password</router-link>
        </div>

        <label for class="label">Your Birthday</label>

        <div class="field relationship">
          <label for class='label'>Month</label>
          <div class='select'>
            <select v-model='user_birthday_month' required @change="flagChange=true">
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </div>

        <div class="field relationship">
            <label for class='label'>Day</label>
            <div class='select'>
              <select v-model='user_birthday_day' required @change="flagChange=true">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
          </div>
        </div>

        <div class="field">

            <label for class='label'>Year</label>
            <div class='select'>
              <select v-model='user_birthday_year' required @change="flagChange=true">
                <option v-for="(year, idx) in years" :value="year" :selected="idx === 0" :key='idx'>{{ year }}</option>
              </select>
            </div>
        </div>
        </section>
        <hr>
        
        <h2>Notifications</h2>
        <section class="form-page">
        
          
          <label for class="label">How often do you want to be reminded to create a memory?</label>
          <div class="select">
                <select v-model="user_notifications" @change="flagChange=true">
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Never">Never</option>
                </select>
              </div>
          </section>

          <h2>Account Membership</h2>

          <section class="form-page" v-if="!canceled">
          <label for class="label">Membership automatically renews on:</label>
          {{ sub_end_date }}<br><br>
          <a href='#' @click="openModal">Cancel your membership.</a>
          </section>

          <section class="form-page" v-if="canceled">
          <label for class="label">Membership will be deleted on:</label>
          {{ sub_end_date }}<br><br>
          <a href='#' @click="openReactivateModal">Reactivate your membership.</a>
          </section>
      
      <hr>
        <h2>Who are you creating memories about?</h2>
        <section v-for="(row, idx) in rows" :key="idx" class="form-page">
        
        <hr>
        <div class="columns is-mobile">
          <div class="column">
            <div>
              <font-awesome-icon :icon="['fa', 'user-times']" v-if="row.first_name!=''" @click="confirmDelete(idx)"/>
            </div>
            <div class="field">
              <label for class="label">First Name </label>
              <div class="control">
                <input type="text" class="input" v-model="row.first_name" required @change="flagChange=true"/>
              </div>
            </div>

            <div class="field">
              <label for class="label">Last Name</label>
              <div class="control">
                <input type="text" class="input" v-model="row.last_name" required @change="flagChange=true"/>
              </div>
            </div>


            <div class="text-center">
              <img v-if="row.avatar!=''" :src="row.avatar" width="200" height="200"><br>
              <button class="button" :id="`trigger${idx}`" @click="setRow(idx)">{{ imageButtonText(idx) }}</button>
              <avatar-cropper
                :trigger="`#trigger${idx}`"
                :upload-handler="handleUploading"
                :labels="{submit: 'OK', cancel: 'Cancel'}"
                :output-options="imageSize"
                :output-mime-type="imageType"
                :output-quality="imageQuality"
              />
            </div>
          </div>
        </div>

        <label for class="label">Birthday</label>

        <div class="field relationship">
          <label for class='label'>Month</label>
          <div class='select'>
            <select v-model='row.birthday_month' required @change="flagChange=true">
              <option value="1" selected>January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </div>

        <div class="field relationship">
            <label for class='label'>Day</label>
            <div class='select'>
              <select v-model='row.birthday_day' required @change="flagChange=true">
                <option value="1" selected>1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
          </div>
        </div>

        <div class="field">

            <label for class='label'>Year</label>
            <div class='select'>
              <select v-model='row.birthday_year' required @change="flagChange=true">
                <option v-for="(year, idx) in years" :value="year" :selected="idx === 0" :key='idx'>{{ year }}</option>
              </select>
            </div>
        </div>

           <div class="field relationship">
              <label for class="label">Pronoun</label>
              <div class="select">
                <select v-model="row.pronoun" required @change="flagChange=true">
                  <option value="His">His</option>
                  <option value="Her">Her</option>
                  <option value="Their">Their</option>
                </select>
              </div>
            </div>
            <div class="field " >
            <label for class="label">Relationship to You</label>
              <div class="select">
                <select v-model="row.relationship" required @change="flagChange=true">
                  <option value="Son">Son</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Husband">Husband</option>
                  <option value="Wife">Wife</option>
                  <option value="Girlfriend">Girlfriend</option>
                  <option value="Boyfriend">Boyfriend</option>
                  <option value="Partner">Partner</option>
                </select>
              </div>
            </div>
      </section>
      <hr>
      <button class="button whitebutton" type="button" @click="addPerson">{{ personButton }}</button><br>
      <br>
      <hr>
      <button class="button form-page" @click='savePreferences'>Save</button><br>

    <div class="modal is-active" v-if="showModal">
      <div class="modal-background"></div>
        <div class="modal-content">
          <div class='container'>
            <br>
            <h2>Are you sure?</h2>
            <p>Deleting your account will remove all of your memories and images.</p>
            <p>There is no going back.  We cannot restore deleted accounts!</p>
            <button class="button" @click='cancelAccount'>Delete my account</button>&nbsp;
            <button class="button" @click='closeModal'>No, don't delete my account!</button>
          </div>
        </div>      
    </div>

    <div class="modal is-active" v-if="showCancel">
      <div class="modal-background"></div>
        <div class="modal-content">
          <div class='container'>
            <br><br><br>
            <h2>Your subscription is cancelled.</h2>
            <p>You can still use the service through {{ sub_end_date }}.</p>
            <p>At that time we will delete all memories and images.</p>
            <button class="button" @click='closeCancel'>Continue</button>&nbsp;
          </div>
        </div>      
    </div>




    <div class="modal is-active" v-if="showReactivateModal">
      <div class="modal-background"></div>
        <div class="modal-content">
          <button class="modal-close is-large" aria-label="close" @click="closeReactivateModal"></button>

          <div class='container'>
            <br><br><br>
            <h2>Are you sure?</h2>
            <p>Your account will continue.</p>
            <p>It will renew on your {{ sub_end_date }}.</p>
            <button class="button" @click='reactivateAccount'>Reactivate my account</button>&nbsp;
            <button class="button" @click='closeReactivateModal'>No, don't reactivate my account!</button>
          </div>
        </div>      
    </div>

    <div class="modal is-active" v-if="showReactivateCancel">
      <div class="modal-background"></div>
        <div class="modal-content">
          <div class='container'>
            <br><br><br>
            <h2>Your subscription is reactivated.</h2>
            <p>Your subscription will renew on {{ sub_end_date }}.</p>
            <button class="button" @click='closeReactivateCancel'>Continue</button>&nbsp;
          </div>
        </div>      
    </div>

    <div class="modal is-active" v-if="showDeletePerson">
      <div class="modal-background"></div>
        <div class="modal-content">
          <div class='container'>
            <br><br><br>
            <h2>Are you sure?</h2>
            <p>Are you sure you want to delete this person?</p>
            <button class="button" @click='removePerson'>Yes</button>&nbsp;
            <button class="button" @click='showDeletePerson=false'>No</button>
          </div>
        </div>      
    </div>

    <div class="modal is-active" v-if="showAccountSave">
      <div class="modal-background"></div>
        <div class="modal-content">
          <div class='container'>
            <br><br><br>
            <h2>Are you sure you want to leave?</h2>
            <p>You haven't saved your changes.  Are you sure you want to leave?</p>
            <button class="button" @click='goAway'>Yes</button>&nbsp;
            <button class="button" @click='stayHere'>No</button>&nbsp;
          </div>
        </div>      
    </div>

    <div class="loader-wrapper" v-if="isLoading">
      <div class="loader is-loading">
      </div>
    </div>
  </div>

  
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage';
import { db } from '@/main'
import Datepicker from 'vuejs-datepicker'
import axios from 'axios'
import AvatarCropper from "vue-avatar-cropper"

export default {
  name: 'Account',
  data () {
    return {
      rows: [],
      sub_end_date: '',
      user_birthday_month: '',
      user_birthday_day: '',
      user_birthday_year: '',
      user_notifications: "Weekly",
      canceled: false,
      years:[],
      showModal: false,
      showCancel: false,
      showReactivateModal: false,
      showReactivateCancel: false,
      showDeletePerson: false,
      showAccountSave: false,
      removeIndex: 0,
      currentRow: 0,
      avatarurl: '',
      isLoading: false,
      uploadValue: 0,
      imageSize: {...{'width': 100, 'height': 100}},
      imageType: "image/jpeg",
      imageQuality: 0.5,
      hasSaved: false,
      to: null,
      flagChange: false
    }
   },
  components: {
    Datepicker,
    AvatarCropper
  },
  firestore() {
     return {
       rows: db.collection('UserPreferences').doc(this.user.data.uid).collection('People'),
     }
   },
  props: ['initial'],
  methods: {
    addPerson () {
      this.rows.push({first_name:"", last_name:"",birthday_month:"1",birthday_day:'1', birthday_year: '2020', pronoun:"His",relationship:"Son",user_id:"",avatar:""})
    },
    confirmDelete(index) {
      this.removeIndex=index
      this.showDeletePerson=true
    },
    removePerson() {
      this.showDeletePerson=false
        this.rows.splice(this.removeIndex,1)
    },
    savePreferences() {
      var self=this;
      self.hasSaved=true
      db.collection("Notifications").doc("Daily").update({
        "Emails": firebase.firestore.FieldValue.arrayRemove(self.user.data.uid + "|" + self.user.data.email)
      })
      db.collection("Notifications").doc("Weekly").update({
        "Emails": firebase.firestore.FieldValue.arrayRemove(self.user.data.uid + "|" + self.user.data.email)
      })

      if (self.user_notifications!="Never") {
        db.collection("Notifications").doc(self.user_notifications).update({
          "Emails": firebase.firestore.FieldValue.arrayUnion(self.user.data.uid + "|" + self.user.data.email)
        })
      }

      db.collection("UserPreferences").doc(self.user.data.uid).set({
        user_birthday_month: self.user_birthday_month.toString(),
        user_birthday_day: self.user_birthday_day.toString(),
        user_birthday_year: self.user_birthday_year.toString(),
        user_notifications: self.user_notifications
      },
      {
        merge: true
      })
      .then(function() {
        db.collection('UserPreferences').doc(self.user.data.uid).collection('People').get()
        .then(function(querySnapshot) {
          var batch = db.batch();
          querySnapshot.forEach(function(doc) {
              batch.delete(doc.ref);
          });
          batch.commit();})
        .then(function() {
            self.rows.forEach(function(item) {
              db.collection('UserPreferences').doc(self.user.data.uid).collection('People').doc().set({
                user_id: self.user.data.uid,
                first_name: item.first_name,
                last_name: item.last_name,
                birthday_month: item.birthday_month.toString(),
                birthday_day: item.birthday_day.toString(),
                birthday_year: item.birthday_year.toString(),
                pronoun: item.pronoun,
                relationship: item.relationship,
                avatar: item.avatar
                })
            })
            self.$router.replace({ name: 'NewMemory'})
          })
      })
    },

    convertDate(birthday) {
        let options = {year: 'numeric', month: 'long', day: 'numeric' };
        let returnDate=new Date(birthday*1000)

        let dateString=returnDate.toLocaleDateString("en-US", options)

        return dateString
    },
    getBirthday() {
      var self = this;
      db.collection("UserPreferences")
        .doc(self.user.data.uid)
        .get()
        .then(doc => {
          var data = doc.data()
          self.user_birthday_month=data.user_birthday_month
          self.user_birthday_day=data.user_birthday_day
          self.user_birthday_year=data.user_birthday_year
          self.canceled=data.canceled

          if (data.user_notifications) {
            self.user_notifications=data.user_notifications
          }
          else {
            self.user_notifications="Weekly"
          }        
        })
    },
    getSubEndDate() {
      var self = this;
      db.collection("stripe_customers")
        .doc(self.user.data.uid)
        .get()
        .then(doc => {
          var data = doc.data()
          self.sub_end_date=this.convertDate(data.subscription.current_period_end)      
        })
    },
    closeModal () {
      this.showModal=false
    },
    openModal () {
      this.showModal=true
    },
    closeCancel () {
      this.showCancel=false
    },
    cancelAccount () {
      this.showModal=false
      this.canceled=true
      const data = {user_id:this.user.data.uid}
      axios.put('https://us-central1-bubs-journal.cloudfunctions.net/cancelEmail', data)
      .catch (function(error){
        console.log(error)
      })
      db.collection("UserPreferences").doc(this.user.data.uid).set({
        canceled: true
      },
      {
        merge: true
      })
      this.showCancel=true
    },
    closeReactivateModal () {
      this.showReactivateModal=false
    },
    openReactivateModal () {
      this.showReactivateModal=true
    },
    closeReactivateCancel () {
      this.showReactivateCancel=false
    },
    reactivateAccount () {
      this.showReactivateModal=false
      this.canceled=false;
      const data = {user_id:this.user.data.uid}
      axios.put('https://us-central1-bubs-journal.cloudfunctions.net/reactivateEmail', data)
      .catch (function(error){
        console.log(error)
      })
      db.collection("UserPreferences").doc(this.user.data.uid).set({
        canceled: false
      },
      {
        merge: true
      })
      this.showReactivateCancel=true
    },
    buildYears () {
      for (var i = 2020; i > 1920; i--) {
        this.years.push(i)
      }
    },
    handleUploading(cropper) {     
      var self = this;
      self.isLoading=true
      var image = new Image();
      image.src = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime)
      self.rows[self.currentRow].avatar=image.src

      image.onload = function() {
        const elem = document.createElement('canvas'); 
        document.body.appendChild(elem);  
        elem.style.visibility = "hidden"
        elem.width = 320
        elem.height = 320

        const ctx = elem.getContext('2d');
        ctx.drawImage(image, 0, 0, 320, 320);
        

        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], file_name, {type: 'image/jpeg',lastModified: Date.now()})
          let uid=self.uuidv4()
          let file_name=uid + ".jpg"
          const storageRef=firebase.storage().ref(`/${self.user.data.uid}/${file_name}`).put(file)

          storageRef.on(`state_changed`,snapshot=>{
            self.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
          ()=>{self.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              self.rows[self.currentRow].avatar=url
              self.isLoading = false
          })
        })
        }, 'image/jpeg', 0.7)
      }
    },   
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    setRow(index) {
      this.currentRow = index
      this.flagChange = true
    },
    imageButtonText (index) {
      if (this.rows[index].avatar=='') {
        return "Add an Image"
      }
      else {
        return "Change the Image"
      }
    },
    stayHere() {
      this.showAccountSave = false;
      this.to = null;
    },
    goAway() {
      this.showAccountSave = false;
      this.$router.push(this.to);
    },
  },
  computed: {
    ...mapGetters ({
      user:"user"
    }),
    personButton () {
      if (this.rows.length>0) {
        return "Add another person"
      }
      else {
        return "Add a person"
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name=='Login') {
      next()
    }
    else {
      if(!this.hasSaved) {
        if (this.to) {
          next()
        } 
        else {
          if(this.flagChange) {
            this.to = to;
            this.showAccountSave = true;
          }
          else {
            next();
          }
          
        }
      }
      else {
        next();
      }
    }
  },
  mounted() {
    this.getBirthday()
    this.buildYears()
    this.getSubEndDate()
  }
}
</script>

<style scoped>
h3 {
  font-size: 1.2em;
}
.relationship {
  float: left;
  padding-right: 10px;
}

.whitebutton {
  background-color: #ffffff;
  color:#000000;
}

.modal-content {
  background-color: #ffffff;
  height: 50%;
}

.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 100;
    z-index: 99999;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}
.loader {
  height: 80px;
  width: 80px;
}

.text-center {
  text-align: center;
}

@media (min-width: 900px) { 
  .text-center {
  text-align: left;
  }
}
</style>
