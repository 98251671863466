import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import NewMemory from '@/components/NewMemory.vue'
import EditMemory from '@/components/EditMemory.vue'
import IdeaLibrary from '@/components/IdeaLibrary.vue'
import Ideas from '@/components/Ideas.vue'
import PersonalIdeas from '@/components/PersonalIdeas.vue'
import Memory from '@/components/Memory.vue'
import MemoryList from '@/components/MemoryList.vue'
import LostPassword from '@/components/LostPassword.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import HowItWorks from '@/components/HowItWorks.vue'
import FAQ from '@/components/FAQ.vue'
import WhoWeAre from '@/components/WhoWeAre.vue'
import Terms from '@/components/Terms.vue'
import Privacy from '@/components/Privacy.vue'
import ContactUs from '@/components/ContactUs.vue'
import Preferences from '@/components/Preferences.vue'
import Account from '@/components/Account.vue'
import Help from '@/components/Help.vue'

import firebase from 'firebase/app'
import 'firebase/auth'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/Home'
  },
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    meta: {
      hideNavigation: true
    }
  },
  {
    path: '/login/:redirect?',
    name: 'Login',
    component: Login,
    props: true,
    meta: {
      hideNavigation: true,
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      hideNavigation: true,
    }
  },
  {
    path: '/newmemory/:idea_id?',
    name: 'NewMemory',
    component: NewMemory,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editmemory/:memory_id',
    name: 'EditMemory',
    component: EditMemory,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/memory/:memory_id',
    name: 'Memory',
    component: Memory,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/MemoryList',
    name: 'MemoryList',
    component: MemoryList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/idealibrary',
    name: 'IdeaLibrary',
    component: IdeaLibrary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ideas/:idea_category',
    name: 'Ideas',
    component: Ideas,
    props: true,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/personalideas/:personid',
    name: 'PersonalIdeas',
    component: PersonalIdeas,
    props: true,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/LostPassword',
    name: 'LostPassword',
    component: LostPassword,
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/HowItWorks',
    name: 'HowItWorks',
    component: HowItWorks,
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ,
  },
  {
    path: '/WhoWeAre',
    name: 'WhoWeAre',
    component: WhoWeAre,
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/Preferences/',
    name: 'Preferences',
    component: Preferences,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Account/',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Help/',
    name: 'Help',
    component: Help,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  routes,

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

router.beforeEach(async (to, from, next) => {
  const currentUser=store.state.user.loggedIn;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser){
    next('/login/' + to.name + "|" + to.params.idea_id);
  } else if (requiresAuth && currentUser) {
    next();  
  } else {
    next();
  }
});

export default router
