<template>
  <div class="container app-content form-page">
    <h1>Sign In</h1>
    <section>
      <div class="columns is-centered">
        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
          <p class='error-message'>{{ error_message }}</p>
          <form action="#" @submit.prevent="submit" class="box">
            <div class="field">
              <label for class="label">Email</label>
              <div class="control has-icons-left">
                <input
                  type="email"
                  placeholder="e.g. kaitlyn.smith@gmail.com"
                  class="input"
                  v-model="form.email"
                  required
                  autofocus
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="envelope" />
                </span>
              </div>
            </div>
            <div class="field">
              <label for class="label">Password</label>
              <div class="control has-icons-left">
                <input
                  type="password"
                  placeholder="*******"
                  class="input"
                  v-model="form.password"
                  required
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="lock" />
                </span>
              </div>
            </div>
            <div class="login-box">
            <button class="button" type="submit">Sign In</button><input class="remember-me" type="checkbox" v-model="form.remember" :checked='true' name="remember" id="remember" value="1"> Remember me? 
                <br><router-link to="/LostPassword">Forgot password?</router-link>
                </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { db } from "@/main";
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
        remember: true,
        
      },
      error_message: '',
      rememberstate: 'none'
    };
  },
  props: ['redirect'],
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    async submit() {
      var self = this;
     
      if (self.form.remember==true) {
        self.rememberstate='local'
      }

      firebase.auth().setPersistence(self.rememberstate)

      let signedIn = await firebase.auth().signInWithEmailAndPassword(self.form.email, self.form.password)
        .catch(function(error) {
          if (error.code=='auth/user-not-found') {
            self.error_message="I'm sorry, we couldn't find that email address. If you haven't signed up yet, click the Sign Up link."
            self.form.email=''
            self.form.password=''
          }
          else if (error.code=='auth/wrong-password') {
            self.error_message="I'm sorry, that password doesn't match what we have.  Please try again."
            self.form.password=''
          }
          else {
            self.error_message=error.message
          }
        })
         
        if (signedIn) {
          let current_date=new Date()
          let current_time=current_date.getTime()
          current_time=current_time/1000

          db.collection("stripe_customers").doc(self.user.data.uid).get()
          .then(doc => {
            var data = doc.data()
            let sub_end_date=data.subscription.current_period_end

            if (sub_end_date>current_time) {
              if (self.redirect) {       
                let nextpageitems=self.redirect.split("|")

                let parameters=nextpageitems[1] + "|" + nextpageitems[2]
                router.replace({ name: nextpageitems[0], params: {idea_id: parameters}})
              }
              else {
                router.replace({ name: 'NewMemory'})
              }             
            }
            else {
              self.error_message="I'm sorry, your subscription has lapsed.  Please contact us to modify your payment method."
            }
          })
          
        }
    }
  },
}
</script>

<style scoped>
.box {
  background-color: #cccccc;
}

.remember-me {
  margin-top: 15px;
  margin-left: 20px;
}

.login-box {
  text-align: center;
}
</style>
