<template>
  <div>
    <div class="container app-content">
          <div class="back-link"><router-link to="/MemoryList" class="back-link">Back to the Memory List</router-link></div>

      <br />
      <div class="card">
        <div class="card-content">
          <p class="title">{{ memory_name | formatName}}</p>
            <ul>
            <li v-for="(tag, idx) in tags" :key="idx">{{ tag.text }}</li>
            </ul>
          <p class="content">{{ memory_date }}</p>
          <p class="ql-editor" v-html="memory_text">{{ memory_text }}</p>
          <img :src="memory_image" />
        </div>
      </div>
      <div class='editbutton'>
        <router-link :to="`/EditMemory/${this.memory_id}`" class='editbutton'><font-awesome-icon :icon="['fas', 'edit']"/></router-link>
        <font-awesome-icon :icon="['fas', 'trash']" @click='confirmDelete' class='handcursor'/>
      </div>        

    </div>

    <div class="modal is-active" v-if="showDelete">
      <div class="modal-background"></div>
        <div class="modal-content">
          <div class='container'>
            <br><br><br>
            <h2>Are you sure?</h2>
            <p>Are you sure you want to delete this memory?</p>
            <button class="button" @click='deleteMemory'>Yes</button>&nbsp;
            <button class="button" @click='showDelete=false'>No</button>
          </div>
        </div>      
    </div>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"
import firebase from 'firebase/app'
import 'firebase/firestore'
import { db } from '@/main'
import VueTagsInput from '@johmun/vue-tags-input'


export default {
  name: 'Memory',
  components: {
    VueTagsInput
  },
  data () {
    return {
      memory_name: '',
      memory_text: '',
      memory_image: '',
      memory_date: '',
      tag: '',
      tags: [],
      disabled: true,
      showDelete: false
    }
   },
  methods: {
    getMemoryInfo() {
      var self = this;
      db.collection('UserPreferences').doc(self.user.data.uid).collection("Memories")
        .doc(self.memory_id)
        .get()
        .then(doc => {
          var data = doc.data()
          self.memory_name=data.memory_name
          self.memory_text=data.memory_text
          self.memory_image=data.memory_image
          self.memory_date=this.convertDate(data.memory_date.seconds)
          self.tags=data.memory_tags
        })
    },
    convertDate(memory_date) {
      let options = {year: 'numeric', month: 'long', day: 'numeric' };
      let returnDate=new Date(memory_date*1000)

      let dateString=returnDate.toLocaleDateString("en-US", options)

      return dateString
    },
    confirmDelete() {
      this.showDelete=true
    },
    deleteMemory() {
      var self = this;

      if (self.memory_image) {
        let start = self.memory_image.indexOf('%2F') + 3
        let end = self.memory_image.indexOf('?alt')
        let length = end - start
        let file_name = self.memory_image.substr(start, length)
        
        let deleteRef = firebase.storage().ref(`/${self.user.data.uid}/${file_name}`)
        deleteRef.delete()
      } 
      db.collection('UserPreferences').doc(self.user.data.uid).collection("Memories").doc(self.memory_id).delete()
      
      self.$router.replace({ name: 'MemoryList' })
    }
  },
  filters: {
    formatName: function (value) {
      let secondvalue=value.seconds*1000
      let newdate=new Date(secondvalue)
      
      var valid = newdate.getTime() > 0

      if (valid) {
        let datemonth=newdate.getMonth()
        let dateday=newdate.getDate()
        let dateyear=newdate.getFullYear()

        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        return months[datemonth] + " " + dateday + ", " + dateyear
      }
      else {
        return value
      }
    }
  },

  props: ['memory_id'],
  computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  created () {
    this.getMemoryInfo()
  },
  mounted () {
    for (let el of document.querySelectorAll('.vue-tags-input .ti-icon-close')) console.log("hi")
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 20px;
}
li {
  background-color: #678036;
  color: white;
  margin: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
}
.handcursor {
  cursor: pointer;
}

.editbutton {
  padding-right: 30px;
}

.modal-content {
  background-color: #ffffff;
  height: 50%;
}
</style>

