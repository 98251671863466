import Vue from 'vue'

Vue.mixin({
    methods: {
      replaceName (value, first_name, pronoun) {
        value=value.replace('firstname', first_name)
        value=value.replace('firstname', first_name)
        value=value.replace('firstname', first_name)
  
        let firstpronoun=''
        let secondpronoun=''
        let thirdpronoun=''
        let fourthpronoun=''
        
                if (pronoun=="His") {
                  firstpronoun='his'
                  secondpronoun='he'
                  thirdpronoun='himself'
                  fourthpronoun='him'
                }
                else if (pronoun=="Her") {
                  firstpronoun='her'
                  secondpronoun="she"
                  thirdpronoun="herself"
                  fourthpronoun="her"
                }
                else if (pronoun=="They") {
                  firstpronoun='they'
                  secondpronoun="their"
                  thirdpronoun="themself"
                  fourthpronoun='they'
                }
  
                value=value.replace("his/her", firstpronoun)
                value=value.replace("he/she", secondpronoun)
                value=value.replace("himself/herself", thirdpronoun)
                value=value.replace("him/her", fourthpronoun)
  
                value=value.replace("his/her", firstpronoun)
                value=value.replace("he/she", secondpronoun)
                value=value.replace("himself/herself", thirdpronoun)
                value=value.replace("him/her", fourthpronoun)
  
        return value
      }
    }
  })