<template>
  <div class="container app-content form-page">
    <section>
      <div class="columns is-centered">
        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
          <h1>Forgot Your Password?</h1><br>
          <p class='status-message'>{{ status_message }}</p>
          <form action="#" @submit.prevent="submit" class="box">
            <div class="field">
              <label for class="label">Email Address</label>
              <div class="control has-icons-left">
                <input
                  type="email"
                  placeholder="e.g. kaitlyn.smith@gmail.com"
                  class="input"
                  v-model="form.email"
                  required
                  autofocus
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="envelope" />
                </span>
              </div>
            </div>
          
            <div class="field">
              <button class="button" type="submit">Send a Link</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import router from '@/router'

export default {
  name: "LostPassword",
  data() {
    return {
      form: {
        email: ""
      },
      status_message: 'Bummer. Enter the email address you use for your Bubs account and we’ll send you instructions on how to reset your password.'
    };
  },
  methods: {
    submit() {
      var self = this;
      
      firebase
        .auth()
        .sendPasswordResetEmail(this.form.email)
        .then(function() {
          self.$router.push({ name: 'Login' })
        })
        .catch(function(error) {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.box {
  background-color: #cccccc;
}
</style>
