<template>
<div class='container idea-box'>
  <div class='idea-text'>
    {{ idea_text + idea_start }}
  </div>
  <button class='button is-info is-small' @click='showIdea'>{{ button_text }}</button></div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import { db } from '@/main'
import { mapGetters } from "vuex"
import store from '@/store/index'
import ideas from '@/ideas.json'
import nationaldays from '@/nationaldays.json'
import codes from '@/codes.json'

export default {
  name: "IdeaDisplay",
  data: function() {
    return {
      idealist: ideas,
      dayslist: nationaldays,
      codelist: codes,
      idea_text: '',
      button_text: '',
      button_number: 1,
      people: [],
      idea_start:'',
      birthday_day: 1,
      birthday_month: 1,
      birthday_year: 1970

    };
  },
  firestore() {
    return {
      people: db.collection('UserPreferences').doc(this.user.data.uid).collection('People')
    }
   },
  methods: {
    showIdea () {
      var self = this;
      self.idea_start=''
      let show_this=0

      switch (self.button_number) {
        case 1: self.button_text='Inspire me.'
        break;        
        case 2: self.button_text='Nah, try another one.'
        break;        
        case 3: self.button_text='More ideas please.'
        break;
        case 4: self.button_text='Keep trying.'
        break;
        case 5: self.button_text='Not doing it for me.'
        break;
        case 6: self.button_text='Nope.'
        break;
        case 7: self.button_text='Try again.'
        break;
        case 8: self.button_text='Ugh. No, thank you.'
        break;
        case 9: self.button_text="Let's try again."
        break;
        case 10: self.button_text='Not my cup of tea.'
        break;
        case 11: self.button_text='Please, sir. I want some more.'
        break;
      }


      let idea_number=Math.floor(Math.random() * self.idealist.length)
      let holiday_code=self.idealist[idea_number].Code
      let age=0
      let range_check=true
      let relationship_check=true
      let current_date=new Date()
      let current_month=current_date.getMonth()
      let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      let month_name=months[current_month]
      let current_time=Math.round(current_date.getTime()/1000)
      let daystring = month_name + " " + current_date.getDate()
      let todaysdaylist = self.dayslist.filter(d => d.Date == daystring) 


      //Maybe show a national day
      if (self.button_number<4) {
        let coinflip=Math.floor(Math.random() * 8)
        
        if (coinflip==1) {
          let randomnationalday=Math.floor(Math.random() * todaysdaylist.length)
          self.idea_text="It's " + todaysdaylist[randomnationalday].National_Day + "!"
          self.button_number++
          return
        }
      }

      //Check for a holiday or month restriction
      if(holiday_code) {
        if (holiday_code=='M') {
          let monthlist=self.idealist[idea_number].Months.filter(function(monthname) {
            monthname == current_month
          })

          if (monthlist.length>0) {
            show_this=1
          }
          else {
            show_this=0
          }
        }
        else {
          let codedetail = self.codelist.filter(d => d.Code === holiday_code)
          let holiday_start = codedetail[0].Start
          let holiday_end = codedetail[0].End
          let idea_start=self.idealist[idea_number].Start
          let idea_end=self.idealist[idea_number].End

          let display_start=holiday_start-idea_start
          let display_end=holiday_end+idea_end
          if(current_time>=display_start && current_time<=display_end) {
            show_this=1
          }
          else {
            show_this=0
          }
        }
      }
      else {
        show_this=1
      }

      //Range check on non personalized here
      if (self.idealist[idea_number].Personalized=="N") {
        if(self.idealist[idea_number].Range) {
          //Get age
          age = self.getAge(self.birthday_day, self.birthday_month-1, self.birthday_year, current_date)
          range_check=self.checkRange(self.idealist[idea_number].Range, age)
        }

        if (range_check==true) {
          show_this=show_this
        }
      else {  
        show_this=0
      }
      }
      
      //Restriction check here
      if (self.idealist[idea_number].Restriction) {
        relationship_check=self.checkRelationship(self.idealist[idea_number].Restriction)            
      }
      if (relationship_check==true) {
          show_this=show_this
        }
      else {  
        show_this=0
      }

      if (show_this>0) {
        
        if (self.idealist[idea_number].Personalized=="Y") {
          //Don't show personalized if there are no people
          if (self.people.length==0) {
            self.showIdea()
          }
          else {
            //Choose a random person
            let person_number=Math.floor(Math.random() * self.people.length)
            let person_name=self.people[person_number].first_name
            let person_pronoun=self.people[person_number].pronoun

            //Figure out the age
            age = self.getAge(self.people[person_number].birthday_day, self.people[person_number].birthday_month-1, self.people[person_number].birthday_year, current_date)
            
            if (self.idealist[idea_number].Range) {
              range_check=self.checkRange(self.idealist[idea_number].Range, age)
            }
            
            if (range_check==true) {
              self.idea_text=self.replaceName(self.idealist[idea_number].Idea, person_name, person_pronoun)
            }
            else {
              self.showIdea()
            }

            self.button_number++
            if (self.button_number>11) {
              self.button_number=1
            }
          }   
      }
      else {
        self.idea_text=self.idealist[idea_number].Idea
        self.button_number++
        if (self.button_number>11) {
            self.button_number=1
        }
      }
      }
      else {
        self.showIdea()
      }      
    },
    getAge(day,month,year,current_date) {
      var birthDate = new Date(year,month,day)   
      var age = current_date.getFullYear() - birthDate.getFullYear();
      var m = current_date.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && current_date.getDate() < birthDate.getDate())) {
        age--;
      }
      return age
    },
    checkRange(age_range,age) {
      let rangearray=[]
      let range_start=0
      let range_end=1000
      let range_check=false
      rangearray = age_range.split('-')
      range_start = parseInt(rangearray[0],10)
      range_end = parseInt(rangearray[1], 10)
      if (age>=range_start && age<=range_end) {
        range_check=true
      }
      return range_check
    },
    checkRelationship(relationship) {
      let self=this
      let relationshipcheck=false

      self.people.forEach (function(entry) {
        if (relationship=="Children") {
          if (entry.relationship=="Son" || entry.relationship=="Daughter") {
            relationshipcheck=true
          }
        }
        else if (relationship=="Partner") {
          if (entry.relationship=="Husband" || entry.relationship=="Wife" || entry.relationship=="Partner" || entry.relationship=="Boyfriend" || entry.relationship=="Girlfiend") {
            relationshipcheck=true
          }
        }
        else {
          if(entry.relationship==relationship) {
            relationshipcheck=true
          }
        }
      })

      return relationshipcheck
    }
  },
  computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  mounted () {
    db.collection("UserPreferences").doc(this.user.data.uid).get()
    .then(doc => {
      var data = doc.data()
      this.birthday_month=data.user_birthday_month
      this.birthday_day=data.user_birthday_day
      this.birthday_year=data.user_birthday_year  
      this.showIdea()
    })
  }
};
</script>

<style scoped>
.button {
  background-color: #678036;
}

.button.is-info:hover {
    background-color: #678036;
}

.idea-text {
  padding-bottom: 10px;
}
</style>
