<template>
  <div class="container app-content form-page">
    <h1>Sign Up</h1>
    <p class='error-message'>{{ error_message }}</p>
    <form action="#" @submit.prevent="submit" class='box'>

      <div class="form-group" :class="{ 'form-group--error': $v.name.$error }">
        <div class="field">
          <label for class="form__label label">Your Name</label>
          <input
            type="text"
            placeholder="e.g. Kaitlyn Smith"
            class="input form__input"
            v-model.trim="$v.name.$model"
          />
        </div>
      </div>
      <div v-if="$v.name.$dirty">
      <div class="error" v-if="!$v.name.required">This field is required</div>
      <div class="error" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div>
      </div>

      <div class="form-group" :class="{ 'form-group--error': $v.email.$error }">
        <div class="field">
          <label for class="label">Your Email Address</label>
          <div class="control has-icons-left">
            <input
              type="email"
              placeholder="e.g. kaitlyn.smith@gmail.com"
              class="input"
              v-model.trim="$v.email.$model"
            />
            <span class="icon is-small is-left">
              <font-awesome-icon icon="envelope" />
            </span>
          </div>
        </div>
      </div>
      <div v-if="$v.email.$dirty">
      <div class="error" v-if="!$v.email.required">This field is required</div>
      <div class="error" v-if="!$v.email.email">Please enter a valid email address</div>
      </div>

      <div class="form-group" :class="{ 'form-group--error': $v.password.$error }">
        <div class="field">
          <label for class="label">Password</label>
          <div class="control has-icons-left">
            <input
              type="password"
              placeholder="*******"
              class="input"
              v-model="$v.password.$model"
            />
            <span class="icon is-small is-left">
              <font-awesome-icon icon="lock" />
            </span>
          </div>
        </div>
      </div>
      <div v-if="$v.password.$dirty">
      <div class="error" v-if="!$v.password.required">This field is required</div>
      <div class="error" v-if="!$v.password.minLength">Your password must have at least 6 characters</div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="card plans">
            <p><b>Yearly Subscription</b></p>
            <p>$14.99 a year</p>
            (save 9%)
            <p><input type="radio" class="radio" v-model="subscription" id="yearly" value="yearly" checked></p>
          </div>
        </div>
        <div class="column">
          <div class="card plans">
            <p><b>Monthly Subscription</b></p>
            <p>$1.49 a month</p>
            <br>
            <p><input type="radio" class="radio" v-model="subscription" id="monthly" value="monthly"></p>
          </div>
        </div>
      </div>
      <div class="trial_text">
Every subscription has a free 7 day trial period. We hope you enjoy using Bubs, but if you don't, you must cancel your subscription before the trial expires or you will be charged  for your full subscription.      </div>
      <br>
      <div class='container' v-if="subscription=='yearly'">
        Promo Code:<input type='text' class='input' placeholder="Enter the coupon code here" v-model="couponcode" :disabled="isVerified">
        <button type='button' class='button is-light' v-if="isVerified==false" @click='verifyCoupon'>Verify</button>
        {{ verifyError }}
      <div v-if="isVerified==true">You will be charged {{ dynamicPrice }} subscription to Bubs.</div>
      </div>
      
      <p class="error-message">{{ error }}</p>


      <div class="form-group" :class="{ 'form-group--error': $v.number.$error }">
        <div class="field">
          <label for class="label">Credit Card Number</label>
          <input v-model="$v.number.$model" class="input">
        </div>
      </div>
      <div v-if="$v.number.$dirty">
      <div class="error" v-if="!$v.number.required">This field is required</div>
      <div class="error" v-if="!$v.number.numeric">This doesn't look like a credit card number</div>
      </div>

      <div class="form-group" :class="{ 'form-group--error': $v.ccv.$error }">
        <div class="field">
          <label for class="label">CCV</label>
          <input v-model="$v.ccv.$model" class="input" style="width:50%; text-align:left;">
        </div>
      </div>
      <div v-if="$v.ccv.$dirty">
      <div class="error" v-if="!$v.ccv.required">This field is required</div>
      <div class="error" v-if="!$v.ccv.numeric">This doesn't look like a CCV</div>
      <div class="error" v-if="!$v.ccv.minLength">This doesn't look like a CCV</div>
      <div class="error" v-if="!$v.ccv.maxLength">This doesn't look like a CCV</div>
      </div>

      <div class='columns'>
        <div class='column is-one-quarter'>
          <div class="form-group" :class="{ 'form-group--error': $v.exp_month.$error }">
            <div class="field">
              <label for class="label">Expiration Month</label>
              <input v-model="$v.exp_month.$model" size="8" class="input" style="width: 50%;" placeholder="Month">
            </div>
          </div>
      
          <div v-if="$v.exp_month.$dirty">
            <div class="error" v-if="!$v.exp_month.required">This field is required</div>
            <div class="error" v-if="!$v.exp_month.numeric">This doesn't look like a valid month</div>
            <div class="error" v-if="!$v.exp_month.between">This doesn't look like a valid month</div>
          </div>
        </div>

        <div class='column is-one-quarter'>
          <div class="form-group" :class="{ 'form-group--error': $v.exp_year.$error }">
            <div class="field">
              <label for class="label">Expiration Year</label>
              <input v-model="$v.exp_year.$model" size="8"  class="input" style="width: 50%;" placeholder="Year">
            </div>
          </div>
          <div v-if="$v.exp_year.$dirty">
            <div class="error" v-if="!$v.exp_year.required">This field is required</div>
            <div class="error" v-if="!$v.exp_year.numeric">This doesn't look like a valid year</div>
            <div class="error" v-if="!$v.exp_year.between">This doesn't look like a valid year</div>
          </div>
        </div>
      </div>

      <div class="form-group" :class="{ 'form-group--error': $v.address_zip.$error }">
        <div class="field">
          <label for class="label">Billing Zip Code</label>
          <input v-model="$v.address_zip.$model" class="input" > 
        </div>
      </div>
      <div v-if="$v.address_zip.$dirty">
      <div class="error" v-if="!$v.address_zip.required">This field is required</div>
      <div class="error" v-if="!$v.address_zip.numeric">This zip code is not all numbers</div>
      <div class="error" v-if="!$v.address_zip.minLength">This isn't long enough to be a zip code</div>
      <div class="error" v-if="!$v.address_zip.maxLength">This is too long to be a zip code</div>
      </div>
      
      <div class="field">
        <input class="is-checkradio" id="terms" type="checkbox" v-model="terms">
        <label for="termas">&nbsp;By checking this box, you agree to the <router-link to="/Terms">Terms and Conditions</router-link> of this site.</label>
      </div>
      <p class='terms_message'>{{ terms_message }}</p>
      <label class="checkbox">
      
      </label>
      <div class="field">
        <button class="button" v-bind:class="{'is-loading': isLoading}" type="submit">Get Started</button>   <input type="checkbox" v-model="remember" :checked='true' > Remember me?     
      </div>

    <div class="loader-wrapper" v-if="isLoading">
      <div class="loader is-loading">
      </div>
    </div>

    </form>
          
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { db } from "@/main";
import store from '@/store'
import Terms from '@/components/Terms'
import { required, minLength, maxLength, between, numeric, email } from 'vuelidate/lib/validators'
Stripe.setPublishableKey(process.env.VUE_APP_STRIPEPUBLIC);

export default {
  name: "Register",
  components: {
    Terms
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      remember: true,
      terms: 0,
      number: '',
      ccv: '',
      exp_month: '',
      exp_year: '',
      address_zip: '',
      error: '',
      user_id: '',
      error_message: '',
      isLoading: false,
      subscription: 'yearly',
      isVerified: false,
      couponcode: '',
      verifyError: '',
      terms_message: ''
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    number: {
      required,
      numeric
    },
    ccv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(3),
      numeric
    },
    exp_month: {
      required,
      between: between(1,12),
      numeric

    },
    exp_year: {
      required,
      between: between(2020,3000),
      numeric
    },
    address_zip: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(5),
      between: between(0,99999),
      numeric
    }
  },
  methods: {
    submit() {
      var self = this;

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      self.terms_message = ""
   

      if (self.terms == false) {
        self.terms_message = "Please check this box to agree to the terms and conditions"
        return
      } 

      self.isLoading=true

      let subscription_choice=''

      if (self.subscription=='monthly') {
        subscription_choice='monthly'
      }
      else {
        if (self.isVerified==true) {
          subscription_choice='yearly-coupon'
        }
        else {
          subscription_choice='yearly'
        }
      }

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(data => {
          self.user_id=data.user.uid
          data.user
            .updateProfile({
              displayName: this.name
            })
            .then(function() {
              store.state.user.data.displayName = self.name
              db.collection("stripe_customers").doc(self.user_id).set({
                subscription_choice: subscription_choice
              })
              .then(function() {
                self.submitNewCreditCard()
              })
                                                     
            })
          })
        .catch(function(error) {
          self.isLoading=false
          self.terms_message = ''
          if (error.code=='auth/email-already-in-use') {
            self.error_message="I’m sorry. That email address is already in use. Please sign in or use another email address."
          }
          else if (error.code=='auth/weak-password') {
            self.error_message="That password is too weak.  Make sure it is at least 6 characters.";
          }
          else {
            self.error_message=error.message
          }
        })
      },
      submitNewCreditCard: function() {
        var self = this;
        self.isLoading=true
        Stripe.card.createToken({
          number: this.number,
          cvc: this.cvc,
          exp_month: this.exp_month,
          exp_year: this.exp_year,
          address_zip: this.address_zip
        }, (status, response) => {
          if (response.error) {
            this.error = response.error.message;
          } else {
            firebase.firestore().collection('stripe_customers').doc(self.user_id).collection('tokens').add({token: response.id}).then(() => {
              self.newCreditCard = {
                number: '',
                cvc: '',
                exp_month: 1,
                exp_year: 2017,
                address_zip: ''
              };
            })
            .then(function() {
              self.isLoading=true
              self.$router.replace({ name: 'Preferences' })
            })
          }
        });
      },
      verifyCoupon () {
        this.verifyError=''
        if (this.couponcode.toLowerCase()=='journal1') {
          this.subscription='yearly'
          this.isVerified=true
          this.verifyError='Promo code was applied.'
        }
        else {
          this.verifyError='That code did not work.'
        }
        
      }
},
computed: {
  dynamicPrice() {
    if (this.subscription=='monthly') {
      return '$1.49 for a monthly'
    }
    else {
      if (this.isVerified==true) {
        return '$13.99 for a one year'
      }
      else {
        return '$14.99 for a one year'
      }
    }
  }
}
}
</script>

<style scoped>
.error-message {
  color: red;
}

.error {
  color: red;
}

.expmonth {
  float: left;
  padding-right: 15px;
}

a {
  text-decoration: underline;
}
.plans {
  text-align: center;
}

.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 100;
    z-index: 99999;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}
.loader {
  height: 80px;
  width: 80px;
}

.terms_message {
  color: red;
}

.trial_text {
  font-size: 0.8em;
}
</style>
