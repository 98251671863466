import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    idea_number: '',
    first_name: '',
    pronoun: ''
  },
  getters: {
    user(state){
      return state.user
    },
    idea_number: state => state.idea_number,
    first_name: state => state.first_name,
    pronoun: state => state.pronoun,

  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    setIdeaNumber(state, value) {
      state.idea_number = value
    },
    setFirstName(state, value) {
      state.first_name = value
    },
    setPronoun(state, value) {
      state.pronoun = value
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid
        });
      } else {
        commit("SET_USER", null);
      }
    }
  }
});