<template>
  <div class="app-content">
    
    <h1>FAQ</h1>

    <div class="form-page">
      <br>
      <b>Question:</b> What kind of ideas do you have?<br>
      <b>Answer:</b> We have all kinds of ideas to help inspire you to write. Silly ones, serious ones, ones about your kids, your spouse, and even your favorite kind of ice cream. We believe everyone has their own story, but sometimes it just takes a little nudge to get it out.<br>
      <br>
      <b>Question:</b> I can’t write. I can’t start a journal.<br>
      <b>Answer:</b> Phooey, of course you can! Don’t think you need to write a novel. Start with five words. They don’t even need to be a sentence or make sense. For example, my five words for today would be: Kitty. Power. Cold. Laundry. Donuts. Does it make sense? To no one but me. Whether you are writing for you or to capture memories for your family, just try. No judgment here.<br>
      <br>
      <b>Question:</b> What’s a Memory?<br>
      <b>Answer:</b> We call each of our journal entries a Memory. Sometimes you are writing about the future or the past, but most of the time you are writing about something happening right now. When you go back someday and read those entries, they will be Memories. See what we did there?<br>
      <br>
      <b>Question:</b> What if I want to stop using Bubs, what happens to my Memories?<br>
      <b>Answer:</b> Well, we’d be sad to see you go, but all of your Memories can be exported and saved so you can continue to have them even if you no longer have a membership with Bubs.<br>
      <br>
      <b>Question:</b> How can I personalize my account?<br>
      <b>Answer:</b> In your account, you set up a profile for someone. Let’s call that someone “Scott”. In Scott’s profile you enter his birthday as June 20, 1980. Then on June 20, Bubs will send you an email notification with ideas to inspire you to write about how Scott spent his birthday.<br> 
      <br>
      You can also tag Scott in a Memory you create. If you want to group all of your memories about Scott, you can sort, save, and print those Scott Memories.<br>
      <br>
      <b>Question:</b> What is included in my membership?<br>
      <b>Answer:</b> Your membership includes unlimited journal entries, ability to upload pictures to journal entries, personalized ideas, and the ability to tag and save memories to PDF.<br>
      <br>
      <b>Question:</b> Can I upload photos?<br>
      <b>Answer:</b> Yes! You can attach the photo to a Memory.<br>
      <br>
      <b>Question</b>: Does my subscription automatically renew?<br>
      <b>Answer</b>: Yes, when you sign up for your Bubs subscription, it does automatically renew. If you decide Bubs isn’t for you, you can cancel your subscription before your subscription automatically renews. Your renewal date can be found on the Account page.<br>
      <br>
      <b>Question</b>: Can I edit the title of my Memory?<br>
      <b>Answer</b>: The title of a New Memory defaults to today’s date, but if you click on the date, you can delete it and enter whatever you want to enter as the New Memory’s title.<br>
      <br>
      <b>Question</b>: If I let my subscription expire and then I reactivate it at a later date, will I be able to access my old Memories?<br>
      <b>Answer</b>: Sadly, no. You can download (via PDF) all of your memories before your subscription expires, but after your subscription ends, we delete your memories. <br>
      <br>
      <b>Question</b>: What if I am prompted with an idea that I don’t want to use? Does it show up in my Memory?<br>
      <b>Answer</b>: Nope. The ideas are just there to inspire you. Whether you choose to use the idea or not, it will not be saved to your Memory. <br>

    </div>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"

export default {
  name: 'FAQ',
  data () {
    return {

    }
   },
  methods: {
    
  },
   computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  created () {
  }
}
</script>