<template>
  <div class="container app-content">
    <h1>Memories</h1>
    
    <div v-if="fullmemories.length > 0">
      <div id="tag-input">
        <vue-tags-input
          v-model="tag"
          :tags="tags"
          @tags-changed="newTags => (tags = newTags)"
          class="input"
          placeholder="Search by tag"
        />
      </div>
      Search memories from<datepicker
        :format="format"
        :input-class="inputclass"
        :clear-button="true"
        :placeholder="startText"
        v-model="start_date"
      ></datepicker>
      To
      <datepicker
        :format="format"
        :input-class="inputclass"
        :clear-button="true"
        :placeholder="endText"
        v-model="end_date"
      ></datepicker>
      {{ error_message }}
      <br>
      <div v-if="memories.length > 0 && mobileOS==false">
        <button class="button" @click="createPDF()">
          Create a PDF of your memories
        </button>
      </div>

      <div id="clear-search-button" v-if="memories.length == 0">
        <h2>No results.</h2>
        <button class="button is-light" @click="clearSearch">
          Clear your search and start again
        </button>
      </div>
    </div>

    <div v-if="fullmemories.length == 0">
      <h2>Looks like you don't have any memories yet.</h2>
    </div>

    <Waterfall :maxCol='2' :resizable='true'>
      <WaterfallItem v-for="(memory, idx) in memories" :key="idx" class="box itembox" :width='300'>
        <router-link :to="`/Memory/${memory.id}`">
          <p class="title">{{ memory.memory_name | formatName }}</p>
        </router-link>
        <span class="ql-editor" v-html="memory.memory_text">
          {{ memory.memory_text }}
        </span>
        <img :src="memory.memory_image" />
      </WaterfallItem>
    </Waterfall>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/firestore";
import { db } from "@/main";
import { Waterfall, WaterfallItem } from "vue2-waterfall";
import VueTagsInput from "@johmun/vue-tags-input";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Datepicker from "vuejs-datepicker";

export default {
  name: "MemoryList",
  data() {
    return {
      fullmemories: [],
      memories: [],
      printmemories: [],
      tag: "",
      tags: [],
      start_date: "",
      end_date: "",
      inputclass: "input",
      format: "MM/dd/yy",
      startText: "(optional)",
      endText: "(optional)",
      error_message: '',
      mobileOS: false
    };
  },
  components: {
    Waterfall,
    WaterfallItem,
    VueTagsInput,
    Datepicker
  },
  firestore() {
    return {
      fullmemories: db
        .collection("UserPreferences")
        .doc(this.user.data.uid)
        .collection("Memories")
        .orderBy("memory_date", "desc")
    };
  },
  methods: {
    convertDate(memory_date) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      let returnDate = new Date(memory_date * 1000);

      let dateString = returnDate.toLocaleDateString("en-US", options);

      return dateString;
    },
    compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const dateA = a.memory_date.seconds
      const dateB = b.memory_date.seconds

      let comparison = 0;
      if (dateA > dateB) {
        comparison = 1;
      } else if (dateA < dateB) {
        comparison = -1;
      }
      return comparison;
    },
    reversecompare(a, b) {
      // Use toUpperCase() to ignore character casing
      const dateA = a.memory_date.seconds
      const dateB = b.memory_date.seconds

      let comparison = 0;
      if (dateA > dateB) {
        comparison = -1;
      } else if (dateA < dateB) {
        comparison = 1;
      }
      return comparison;
    },
    clearSearch() {
      this.tags = [];
      this.start_date = "";
      this.end_date = "";
    },
    createPDF() {
      let pdfName = "Saved_Memories";
      var doc = new jsPDF({ format: "letter" });
      
      var img = new Image();
      img.src = "img/logo.png";

      doc.addImage(img, "PNG", 70, 20, 75, 75);
      
      doc.setFontSize("36");
      doc.text("Memories", 108, 120,{align:'center'});

      doc.setFontSize("24");
      doc.text("Created by", 108, 140, {align:'center'});
      doc.setFontSize("20");
      doc.text(this.user.data.displayName, 108, 160, {align:'center'});
      
      let taglist = "";
      this.tags.forEach(function(element) {
        taglist = taglist + element.text + ",";
      });

      doc.setFontSize("20");
      doc.text(taglist, 108, 180, {align:'center'});

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      let startstring = "";
      let endstring = "";
      this.memories.sort(this.compare)
      this.memories.forEach(function(element,i) {
        if (i==0) {
          startstring=element.memory_date.seconds
        }
        else {
          endstring=element.memory_date.seconds
        }  
      })

      if (this.memories.length==1) {
        endstring=startstring
      }

      let startdate = new Date(startstring * 1000);
      let startmonth = startdate.getMonth();
      let startday = startdate.getDate();
      let startyear = startdate.getFullYear();
      let startdisplay = months[startmonth] + " " + startday + ", " + startyear;

      let enddate = new Date(endstring * 1000);
      let endmonth = enddate.getMonth();
      let endday = enddate.getDate();
      let endyear = enddate.getFullYear();
      let enddisplay = months[endmonth] + " " + endday + ", " + endyear;

      let datestring = startdisplay + " - " + enddisplay;

      doc.setFontSize("20");
      doc.text(datestring, 108, 200, {align:'center'});
      
      this.memories.forEach(function(element) {
        let imageheight=0
        let imagewidth=0
        doc.addPage("letter", "portrait");

        doc.setFontSize("24");
        doc.text(element.memory_name.toString(), 20, 20, { maxWidth: "180" });

        let location=0
        let leftlocation=0

        if (element.memory_image) {
          imageheight=0
          imagewidth=0
          img.src = element.memory_image;
          imageheight=img.height
          imagewidth=img.width

          let ratio=0

          if (imageheight>imagewidth) {
            ratio=imagewidth/imageheight
            imageheight=100
            imagewidth=imageheight*ratio
          }
          else {
            ratio=imageheight/imagewidth
            imagewidth=150
            imageheight=imagewidth*ratio
          }
          leftlocation=(220-imagewidth)/2        
          
          doc.addImage(img, "jpg", leftlocation, 30, imagewidth, imageheight);

          location=location+imageheight   
          img.src=''
        }
        location=location+30
        doc.setFontSize("16");

        
        let displaytext=element.memory_text.toString()
        displaytext="<span style='font-family:sans-serif'>" + displaytext
        displaytext=displaytext.replace(/class/g, "style")
        displaytext=displaytext.replace(/ql-font-serif/g, "font-family: 'serif';")
        displaytext=displaytext.replace(/ql-font-monospace/g, "font-family: 'mono';")

        displaytext=displaytext.replace(/ql-size-small/g, "font-size: .75em;")
        displaytext=displaytext.replace(/ql-size-large/g, "font-size: 1.5em;")
        displaytext=displaytext.replace(/ql-size-huge/g, "font-size: 2.5em;")
        displaytext=displaytext + "</span>"

        doc.fromHTML(displaytext, 20, location, { width: 170 });
        
      });
      
      this.memories.sort(this.reversecompare)
      doc.save(pdfName + ".pdf");
      location.reload()
    },
    runSearch() {
      var self = this;
      self.memories = [];
      self.error_message=''

      let starttimestamp = Math.round(
              new Date(self.start_date).getTime() / 1000
            );
      let endtimestamp = Math.round(
              new Date(self.end_date).getTime() / 1000
            );

      if (
        self.tags.length == 0 &&
        self.start_date == "" &&
        self.end_date == ""
      ) {
        self.memories = self.fullmemories;
      } else {
        if (starttimestamp>endtimestamp) {
          self.error_message="Make sure your start date is before your end date."
          return
        }
        this.fullmemories.forEach(function(entry) {
          let searchflag = 0;
          let searchcount = 0;
          let startcount = 0;
          let endcount = 0;

          //Check whether memory is after start date
          if (self.start_date == "") {
            startcount = 1;
          } else {
            
            if (starttimestamp < entry.memory_date.seconds) {
              startcount = 2;
            }
          }

          if (self.end_date == "") {
            endcount = 1;
          } else {
            
            if (endtimestamp > entry.memory_date.seconds) {
              endcount = 2;
            }
          }

          if (self.tags.length == 0) {
            searchflag = 1;
          } else {
            if (typeof entry.memory_tags != "undefined") {
              const values = Object.values(entry.memory_tags);
              values.forEach(function(newentry) {
                let tagcount = 0;

                self.tags.forEach(function(tagentry) {
                  tagcount = tagcount + 1;

                  if (
                    tagentry.text.toLowerCase() == newentry.text.toLowerCase()
                  ) {
                    searchcount = searchcount + 1;
                  }
                });
                if (searchcount == tagcount) {
                  searchflag = searchflag + 1;
                }
              });
            }
          }
          if (searchflag > 0 && startcount > 0 && endcount > 0) {
            self.memories.push(entry);
          }
        });
        Array.from(new Set(self.memories));
      }
    }
  },
  filters: {
    formatName: function(value) {
      let secondvalue = value.seconds * 1000;
      let newdate = new Date(secondvalue);

      var valid = newdate.getTime() > 0;

      if (valid) {
        let datemonth = newdate.getMonth();
        let dateday = newdate.getDate();
        let dateyear = newdate.getFullYear();

        let months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];

        return months[datemonth] + " " + dateday + ", " + dateyear;
      } else {
        return value;
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  mounted: function() {
    this.memories = this.fullmemories;

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.mobileOS = true
    }

    this.$watch("tags", this.runSearch, { deep: true });
    this.$watch("start_date", this.runSearch, { deep: true });
    this.$watch("end_date", this.runSearch, { deep: true });
  }
};
</script>

<style scoped>
.tile {
  margin: 20px;
}

input {
  min-width: 200px;
}

#tag-input {
  margin-bottom: 15px;
}

.ql-editor {
  padding: 0px;
}
</style>
