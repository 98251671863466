<template>
  <div id="contact-form" class="app-content form-page">
    <h1>Contact Us</h1>
    
    <form action="#" @submit.prevent="sendEmail">
      <div class="field">
        <label for class="label">Your Email Address</label>
          <div class="control has-icons-left">
            <input
              type="email"
              placeholder="e.g. kaitlyn.smith@gmail.com"
              class="input"
              v-model="form.email"
              required
              autofocus
            />
            <span class="icon is-small is-left">
              <font-awesome-icon icon="envelope" />
            </span>
          </div>
      </div>
      <div class="field">
        <label for class="label">Your Name</label>
          <input
            class="input"
            v-model="form.name"
            required
          />
      </div>
      <div class="field">
        <label for class="label">Your question, comment, or issue</label>
        <textarea class="textarea is-medium" v-model='form.question'></textarea>
      </div>
      <button class="button" type="submit">Send</button><br>
      {{ message_text }}
    </form>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"
import axios from 'axios'

export default {
  name: 'ContactUs',
  data () {
    return {
      message_text: "",
      form: {
        email: "",
        name: "",
        question: "",
        
      },
    }
   },
  methods: {
    sendEmail () {
      const data = {email:this.form.email,message:this.form.question}
      axios.put('https://us-central1-bubs-journal.cloudfunctions.net/submitEmail', data)
      .catch (function(error){
        console.log(error)
      })
      this.form.question=''
      this.message_text="Your message has been sent."
    }
  },
   computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  mounted () {
    this.form.name=this.user.data.displayName
    this.form.email=this.user.data.email
  }
}
</script>