<template>
  <div class="container app-content">

    <h1>How Bubs Works</h1>

    <div class="form-page">
      <p>Bubs is an idea-based journal. Because sometimes you feel like writing, but don’t know what to write about.</p>
      <p>We wanted to create a journal that inspired people to write. So we came up with thousands of ideas.</p>
      <p>What kind of ideas? We have silly ones, serious ones, ones about your kids, your spouse, your work wife, your first time driving a car, your favorite flavor of ice cream, and even your secret shame. No one is perfect, so we haven’t thought of everything, but we are adding new ideas all of the time.</p>
      <p>Everyone has their own story, but sometimes it takes a little nudge to get it out.</p>
      <p>Let us inspire you.</p>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"

export default {
  name: 'HowItWorks',
   computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
}
</script>