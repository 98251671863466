<template>
  <div class="container app-content">
    <h1>Who We Are</h1>
    <br>
    <h2>What is Bubs?</h2>

    <div class="form-page">
    <p>My son is Bubs. I didn’t name him Bubs, but shortly after he was born, it became his primary nickname. And because he is my favorite human, when I was brainstorming names for this website, the first name I thought of was Bubs.</p>
    <p>This Bubs was inspired by my Bubs, I created it for all of us.</p>
    </div>
    
    <h2>Why did I start Bubs?</h2>
    <div class="form-page">
      <p>My first journal was a pink Hello Kitty diary with a small lock. I was in sixth grade. I wrote about getting my period, my best friend forever, and my boyfriend whom I had never met (it was a long story perfect for long entries in a Hello Kitty diary).</p>
      <p>I wrote in my journal everyday during my senior year in high school. I kept another journal throughout my senior year in college. I wrote in it everyday. It was a habit, just like eating Doritos or drinking beer (hey, I was in college). I didn’t write about anything profound, but I wrote about what happened that year. Now twenty-odd years later, I have a time capsule of two dramatic, formative, and legendary years of my youth.</p>
      <p>But then life happened. I started my career and well, just got busy, so I stopped journaling.</p>
      <p>Fast forward to a few years ago when I had my son. I wanted to start a baby book to remember the details of his childhood and also create something for him. A journal to track his milestones and also the daily snippets that really make up his life: his first crush (Caroline), his love of sherbet (lemon is his favorite), or whatever came out of his cute mouth (“Any problem” instead of “No problem”).</p>
      <p>I don’t have the discipline (or time) I did in high school and college, so I don’t write everyday. But I write enough. I am capturing memories. Imperfectly. And so can you.</p> 
      <p>It doesn’t have to be perfect. Nothing is. Stop being so hard on yourself.</p>
      <p>Get writing today. We are here to help.</p> 
      <p>Carrie Gray
      <br>
      Founder of Bubs</p>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"

export default {
  name: 'WhoWeAre',
  data () {
    return {

    }
   },
  methods: {
    
  },
   computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  created () {
  }
}
</script>