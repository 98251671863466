<template>
    <div class="container app-content">
        <h1>Terms and Conditions for Bubs Media LLC</h1>
        <p>Last modified: October 8, 2020</p>
        <div class="form-page">
        <section class="section">
            PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS SITE, AS THEY AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS, INCLUDING, BUT NOT LIMITED TO, WAIVERS OF RIGHTS, LIMITATION OF LIABILITY, AND YOUR INDEMNITY TO US, AND THIS AGREEMENT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN COURTS OR JURY TRIALS, AND LIMITS THE REMEDIES AVAILABLE IN THE EVENT OF A DISPUTE. Access to and use of this website  ("Site" or "Service") is provided by Bubs Media LLC ("Bubs", "us" or "we") subject to the following terms (the "Terms" or "Agreement"):
        </section>
        <section class="section">
            <h2>ACCEPTANCE OF TERMS</h2>
            <p>By using this Site, you agree to be legally bound by all of the terms of this Agreement. You should visit this page regularly and review this Agreement for the current terms of this Agreement, since your continued access or use of the Site will be deemed as an irrevocable acceptance of any such changes, modifications or alterations.</p>
        </section>

        <section class="section">
            <h2>REPRESENTATIONS</h2>
            <p>By using this Site, you represent and warrant that you are fully able and legally competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in this Agreement and to abide by and comply with these terms and conditions.</p> 
        </section>

        <section class="section">
            <h2>REGISTRATION</h2>
            <p>In accessing this Site or certain of the resources on the Site, you may be asked to provide registration details including a full name, date of birth, phone number, address, email address, and certain payment information. It is a condition of use of this Site that all the details you provide will be correct, current, and complete. If Bubs believes the details are not correct, current, or complete, we have the right to refuse you access to the Site or any of its resources.</p>        
        </section>

        <section class="section">
            <h2>RESTRICTIONS ON USE OF MATERIALS</h2>
            <p>Other than the content you own, under these Terms, Bubs Media LLC owns all the intellectual property rights and materials contained in this Site.</p>
            <p>Using our Sites does not give you ownership of any intellectual property rights in our Sites or the content you access. You may not use content from our Site unless you obtain permission from Bubs or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Sites. Don’t remove, obscure, or alter any legal notices displayed in or along with our Site.</p>
        </section>

        <section class="section">
            <h2>DISCLAIMER OF WARRANTIES</h2>
            <p>Your use of this Site is at your own risk. This site and all the materials, information, software, facilities, sites and other content in this Site are provided "as is" and "as available" without warranties of any kind, either express or implied. To the fullest extent permissible pursuant to applicable law, Bubs disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose and non-infringement. Bubs does not warrant that the functions contained in this site will be available, uninterrupted or error-free, that defects will be corrected or that Bubs or the servers that make them available are free of viruses or other harmful components. </p> 
        </section>

        <section class="section">
            <h2>LIMITATION OF LIABILITY</h2>
            <p>Bubs does not accept any liability for any loss or damage (direct, indirect, punitive, actual, consequential, incidental, special, exemplary or otherwise) resulting from any use of, or inability to use, this Site or the material, information, software, facilities, sites or other content on this Site.</p> 
        </section>

        <section class="section">
            <h2>YOUR CONTENT</h2>
            <ul>
                <li>No material you send to us will be treated as confidential.</li> 
                <li>Bubs  has the right to use, host, store, reproduce, modify, communicate, publish, publicly perform, publicly by display, and distribute such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving on services and developing new ones. This license can continue even if you stop using our Site.</li> 
                <li>We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review content, so please don’t assume that we do.</li> 
                <li>In connection with your use of the Sites, we may send you Site announcements, administrative messages, and other information. You may opt out of some of those communications.</li> 
                <li>Bubs may also contact you from time to time to conduct market research and surveys in an effort to continually improve our product and Site offerings.</li> 
                <li>We do not sell or rent any personally identifiable Registration Data to third parties. </li> 
            </ul>
        </section>

        <section class="section">
            <h2>RULES OF CONDUCT</h2>
            <p>Please act responsibly when using this Site. You may only use this Site and its contents for lawful purposes and in accordance with applicable law and you are prohibited from storing, distributing or transmitting any unlawful material through this Site. You understand and agree that all materials posted or privately transmitted on or through this Site are the sole responsibility of the sender, not Bubs, and that you are responsible for all material you upload, post or otherwise transmit to or through this Site.</p>
        </section>

        <section class="section">
            <h2>USE OF SUBMITTED CONTENT</h2>
            <p>We are not responsible for any deleted or lost Content.</p>
        </section>

        <section class="section">
            <h2>DEACTIVATION/TERMINATION OF YOUR REGISTRATION</h2>
            <p>You may deactivate your registration on the Site, at any time and for any reason. We may terminate your use of and registration on the Site, at any time and for any reason, with or without cause, without prior notice to you and without any liability or further obligation of any kind whatsoever to you or any other party.</p>
            <p>Should you no longer desire to use the Site, you may deactivate your Account with Bubs. You will have access to Bubs until your Account membership term expires. You will have the ability to save your Content to PDF until your Account membership expires. Once your Account membership expires, your Content will be deleted.</p>
            <p>Bubs may also terminate your Account, in its sole discretion and without any notice, for any reason including, without limitation, violation of these Terms. In the event of a termination of your Account, all Content will be deleted. </p>
            <p>If you cancel your Account membership  before your subscription is over or if your Account membership is terminated, you will not be issued a refund. </p>
            <p>Your Account membership will automatically renew.</p>
            <p>If you do not cancel your membership or trial membership before your membership period ends, your membership will be renewed for the length of your original membership term.</p>
            <p>To unsubscribe from any email communications from Bubs, you can unsubscribe directly from such email communications or deactivate your Account, which can be done from your Account page.</p>
        </section>

        <section class="section">
            <h2>SYSTEM ABUSE</h2>
            <ul>
                <li>You may not reproduce, sell, resell or otherwise exploit any resource, or access to any resource, contained on this Site.</li>
                <li>You are prohibited from using any Sites or facilities provided in connection with this Site to compromise security or tamper with system resources and/or accounts. The use or distribution of tools designed for compromising security (e.g. password guessing programs, cracking tools or network probing tools) is strictly prohibited.</li>
            </ul>
        </section>

        <section class="section">
            <h2>INVESTIGATIONS/VIOLATIONS</h2>
            <p>Bubs reserves the right to investigate suspected violations of this Agreement. Bubs may seek to gather information from the user who is suspected of violating the terms of this Agreement, and from any other user. We will collect and use any such information in accordance with the Bubs Privacy Policy. Bubs may provide personally identifiable information in response to legal process, for example, in response to a court order or a subpoena. We also may disclose such information in response to a law enforcement agency's request. BY ACCEPTING THIS AGREEMENT YOU WAIVE AND HOLD HARMLESS BUBS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY BUBS DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER BUBS OR LAW ENFORCEMENT AUTHORITIES.</p>
        </section>

        <section class="section">
            <h2>INDEMNITY</h2>
            <p>You hereby indemnify to the fullest extent Bubs Media LLC from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
        </section>

        <section class="section">
            <h2>SEVERABILITY</h2>
            <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
        </section>

        <section class="section">
            <h2>ELECTRONIC SIGNATURE</h2>
            <p>With your Bubs membership, your use of the Site may enable you to enter into agreements and/or to make purchases electronically. You acknowledge and agree that your electronic submissions constitute your agreement and intent to be bound by any such agreements and/or purchases, including without limitation all transactions you enter into in connection with the Site.</p>
        </section>

        <section class="section">
            <h2>FORCE MAJEURE</h2>
            <p>Bubs shall not have any liability to you by reason of any delay or failure to perform any obligation hereunder if the delay or failure to perform is occasioned by force majeure, which shall refer to any act of God, storm, fire, casualty, unanticipated work stoppage, power outage, satellite failure, strike, lockout, labor dispute, civil disturbance, riot, war, national emergency, Governmental action, or other cause beyond its control.</p>
        </section>

        <p>BY USING OUR SERVICE, YOU ARE AGREEING TO THESE TERMS.</p>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"

export default {
  name: 'Terms',
  data () {
    return {

    }
   },
  methods: {
    
  },
   computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  created () {
  }
}
</script>

<style scoped>
section {
  padding: 25px;
}
</style>