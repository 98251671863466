<template>
    <div class="container app-content">
    <div class="back-link"><router-link to="/IdeaLibrary" class="back-link">Back to the Idea Library</router-link></div>
   
    <h1>{{ person.first_name }} Ideas</h1>
      
      <div v-for="(idea, idx) in filteredIdeas" :key="idx">

        <div class="card">
          <div class="card-content" >
            <p class="idea-text">{{ replaceName(idea.Idea, person.first_name, person.pronoun) }}</p>
            <router-link :to="`/NewMemory/${idea[0].ideaindex}|${person.id}`"><button class='button'>Use this idea</button></router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"
import firebase from 'firebase/app'
import 'firebase/firestore'
import ideas from '@/ideas.json'
import { db } from '@/main'

export default {
  name: 'PersonalIdeas',
  data () {
    return {
      idealist: ideas,
      filteredIdeas: [],
      person: []
    }
   },
  firestore() {
     return {
       person: db.collection('UserPreferences').doc(this.user.data.uid).collection('People').doc(this.personid)
     }
   },
  props: ['personid'],
  methods: {
    displayIdeas () {
      var self = this
      let age = 0
      let current_date = new Date()
      let range_check = true

      this.idealist.forEach (function(entry, i) {
        if (entry.Personalized=="Y") {
          age = self.getAge(self.person.birthday_day, self.person.birthday_month-1, self.person.birthday_year, current_date)
          if (entry.Range) {
            range_check=self.checkRange(entry.Range, age)
          }
          if (range_check==true) {
            let ideaindex=[{'ideaindex':i}]
            Array.prototype.push.apply(entry, ideaindex)
            self.filteredIdeas.push(entry);            }
        }
      })
    },
    getAge(day,month,year,current_date) {
      var birthDate = new Date(year,month,day)   
      var age = current_date.getFullYear() - birthDate.getFullYear();
      var m = current_date.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && current_date.getDate() < birthDate.getDate())) {
        age--;
      }
      return age
    },
    checkRange(age_range,age) {
      let rangearray=[]
      let range_start=0
      let range_end=1000
      let range_check=false
      rangearray = age_range.split('-')
      range_start = parseInt(rangearray[0],10)
      range_end = parseInt(rangearray[1], 10)
      if (age>=range_start && age<=range_end) {
        range_check=true
      }
      return range_check
    },
  },
  computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  mounted () {
    this.$watch('person',this.displayIdeas,{deep: true})
  }
}
</script>