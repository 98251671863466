<template>
  <div class="container app-content">
    <div class="back-link"><router-link to="/IdeaLibrary" class="back-link">Back to the Idea Library</router-link></div>
    <h1>{{ categoryName(idea_category) }} Ideas</h1>
      <div v-if="idea_category!='nationalday'">
        <div v-for="(idea, idx) in filteredIdeas" :key="idx">
          <div class="card">
            <div class="card-content" >
              <p class="idea-text">{{ idea.ideatext }}</p>
              <router-link :to="`/NewMemory/${idea.ideanumber}|${idea.personnumber}`"><button class='button'>Use this idea</button></router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="idea_category=='nationalday'">
        <div v-for="(idea, idx) in dayslist" :key="idx">
          <div class="card">
            <div class="card-content" >
              <p class="idea-text">{{ idea.Date + " - " + idea.National_Day }}</p>
              <router-link :to="`/NewMemory/n${idx}|x`"><button class='button'>Use this idea</button></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { mapGetters } from "vuex"
import firebase from 'firebase/app'
import 'firebase/firestore'
import { db } from '@/main'
import ideacategories from '@/categories.json'
import ideas from '@/ideas.json'
import nationaldays from '@/nationaldays.json'

export default {
  name: 'Ideas',
  components: {

  },
  data () {
    return {
      idealist: ideas,
      dayslist: nationaldays,
      filteredIdeas: [],
      people: [],
      categories: ideacategories,
      relationships: []
    }
   },
  firestore() {
     return {
       people: db.collection('UserPreferences').doc(this.user.data.uid).collection('People')
     }
   },
  props: ['idea_category'],

  methods: {
    displayIdeas () {
      var self = this
      self.filteredIdeas=[]
      let ideatext=''
      let personnumber=''
      let relationshipcheck = true
      this.idealist.forEach (function(entry, i) {

        entry.Topic.forEach (function(topicentry) {

          if (topicentry.toLowerCase()==self.idea_category) {
            if(entry.Personalized=='Y') {
              self.people.forEach(function(peopleentry) {
                self.relationships.forEach(function(relationshipentry) {
                  if (peopleentry.relationship==relationshipentry) {
                    relationshipcheck = false

                    if (entry.Restriction=="Children") {
                      if (peopleentry.relationship=="Son" || peopleentry.relationship=="Daughter") {
                        relationshipcheck=true
                      }
                    }
                    else if (entry.Restriction=="Partner") {
                      if (peopleentry.relationship=="Husband" || peopleentry.relationship=="Wife" || peopleentry.relationship=="Partner" || peopleentry.relationship=="Boyfriend" || peopleentry.relationship=="Girlfiend") {
                        relationshipcheck=true
                      }
                    }
                    else if (entry.Restriction=="Son") {
                      if (peopleentry.relationship=="Son") {
                        relationshipcheck=true
                      }
                    }
                    else if (entry.Restriction=="Daughter") {
                      if (peopleentry.relationship=="Daughter") {
                        relationshipcheck=true
                      }
                    }
                    else if (entry.Restriction=="Husband") {
                      if (peopleentry.relationship=="Husband") {
                        relationshipcheck=true
                      }
                    }
                    else if (entry.Restriction=="Wife") {
                      if (peopleentry.relationship=="Wife") {
                        relationshipcheck=true
                      }
                    }
                    else if (entry.Restriction=="Boyfriend") {
                      if (peopleentry.relationship=="Boyfriend") {
                        relationshipcheck=true
                      }
                    }
                    else if (entry.Restriction=="Girlfriend") {
                      if (peopleentry.relationship=="Girlfriend") {
                        relationshipcheck=true
                      }
                    }
                    else if (entry.Restriction=="") {
                      relationshipcheck = true
                    }

                    if (relationshipcheck == true ) {
                      ideatext=self.replaceName(entry.Idea, peopleentry.first_name, peopleentry.pronoun)
                      self.filteredIdeas.push({'ideatext':ideatext, 'ideanumber':i, 'personnumber':peopleentry.id})
                    }

                    
                  }
                }) 
              })
            }
            else {
              ideatext=entry.Idea
              personnumber='x'
              self.filteredIdeas.push({'ideatext':ideatext, 'ideanumber':i, 'personnumber':'x'})
            }
          }
        })
      })
    },
    categoryName(category) {
      let categoryname = this.categories.filter(d => d.Link === category)
      this.relationships = categoryname[0].Relationship

      return categoryname[0].Category
    }
  },
  computed: {
    ...mapGetters ({
      user:"user"
    }),
  },
  mounted () {
    this.displayIdeas()
    this.$watch('people',this.displayIdeas,{deep: true})
  }
}
</script>